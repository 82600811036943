import React from 'react';
import Tr from "@util/translate";
import Req from "@/util/fetch";
import classnames from "classnames";

export default class PrinterOrder extends React.Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        const {noKeyPrint} = this.props;
        if (!noKeyPrint) document.addEventListener('keydown', this.keydownAction);
    }

    componentWillUnmount() {
        const {noKeyPrint} = this.props;
        if (!noKeyPrint) document.removeEventListener('keydown', this.keydownAction)
    }

    keydownAction = (e) => {
        if(window.discountEditModal) return ;
        if (e.key === 'PageDown') {
            const {orderData} = this.props;
            if (orderData && orderData.guid) {
                this.printOrder();
            }
        }
    }
    printOrder = () => {
        let isAndroid = false;
        const {orderData, params} = this.props;
        if (orderData && orderData.guid) {
        } else {
            alert("请先打开一个订单");
            return;
        }
        try {
            if (!Android) {
            } else {
                isAndroid = Android.isAndroid && Android.isAndroid()
            }
        } catch (e) {
            console.log(e);
        }
        Req({
                url: `/v1/unionprint?orderGuid=${orderData.guid}&clientType=${isAndroid ? 2 : 1}`,
                type: params ? "POST" : "GET",
                data: params ? {
                    clientType: isAndroid ? 2 : 1,
                    orderGuid: orderData.guid,
                    ...params
                } : null,
            },
            res => {
                console.log(res);
            });
    };

    render() {
        return (
            <div
                className={classnames({
                    menu: true,
                    menuClickActive: true
                })}
                title={'Av Pág'}
                onClick={this.printOrder}
            >
                <img
                    className="active"
                    src="./public/img/aside/dayin_w.png"
                    alt=""
                />
                <img src="./public/img/aside/dayin.png" alt=""/>
                <span>{Tr("print")}(Av Pág)</span>
            </div>
        )
    }
}