import React, {Component, Fragment} from "react";
import {Link, withRouter} from "react-router-dom";
import {connect} from "react-redux";
import { Menu, Icon } from "antd";
import Tr from "@util/translate";
import "./index.scss";

const {Item} = Menu;
const SubMenu = Menu.SubMenu

export default
@withRouter
@connect(
    state => {
        return {
            userInfo: state.global.userInfo || null,
        };
    },
    dispatch => { return {}; }
)
class LeftMenu extends Component {
    constructor() {
        super();
    }
    state = {
        openKeys: []
    };
    gotoPage = (e, url, code)=>{
        if(!code){
            location.hash = url;
            return ;
        }
        if(window.hasPermisionCode(code)){
            window.confrimPasswordAction(code, ()=>{
                location.hash = url;
            });
        }else{
            alert(Tr("noOpenChashPer"));
            e.stopPropagation();
        }
    }
    jumpToChoice = ()=>{
        window.getAllGoodsApiRoot && window.getAllGoodsApiRoot();
        setTimeout(()=>{
            if(sessionStorage.getItem('backUrl')){
                location.href = decodeURIComponent(sessionStorage.getItem('backUrl'));
            }else{
                location.hash = '/content/choice';
            }
        })
    }

    render() {
        const {location, userInfo} = this.props || {};
        const { openKeys } = this.state;
        let selected =
            "/" +
            location.pathname
                .split("/")
                .splice(0, 3)
                .join("/");
        selected = selected.slice(1);

        let guid ;
        if(localStorage.getItem("userInfo")){
            guid = JSON.parse(localStorage.getItem("userInfo")).currentStoreGuid
        }else{
            location.hash="/"
        }

        let storeData = JSON.parse(localStorage.getItem("storeData")) || {};
        let storeInfo = storeData.currentStore || {};
        let open_integral = storeInfo.storeConf && storeInfo.storeConf.open_integral;

        return (
            <div className="admin-left-menu">
                <div className="logo-container">
                    <div onClick={ this.jumpToChoice } style={{cursor: 'pointer'}}>
                        {
                            userInfo.agent && userInfo.agent.is_use_logo ?
                                <img src={ userInfo.agent.logo } alt="" style={{ width: 70,minHeight: 20 }} />
                                :
                                <img src="./public/img/logo.png" alt="" style={{ width: 70,minHeight: 20 }} />
                        }
                    </div>
                </div>
                <Menu
                    style={{width: "100%"}}
                    mode="inline"
                    theme="dark"
                    onClick={this.menuClick}
                    openKeys={ openKeys }
                    onOpenChange={ openKeys => {
                        const latestOpenKey = openKeys.find(key => this.state.openKeys.indexOf(key) === -1);
                        this.setState({
                            openKeys: [latestOpenKey]
                        });
                    }}
                    defaultSelectedKeys={[selected]}
                >
                    <Item key="/admin/rank" onClick={ ()=>this.setState({ openKeys: [] }) }>
                        <Link to="/admin/rank">
                            <Icon style={{ marginRight: 14 }} type="bar-chart" />
                            <span>{ Tr("add43") }</span>
                        </Link>
                    </Item>
                    <SubMenu key="goods" title={<span><i
                        className="icon iconfont icon-shangpinliebiao"> </i><span>{ Tr("adminLeftMenu1") }</span></span>}>
                        <Item key="/admin/goods">
                            <a href="javascript:;" onClick={ (e)=>this.gotoPage(e, "/admin/goods/list", 'COMMODITY_LIST') }>
                                { Tr("adminLeftMenu2") }
                            </a>
                        </Item>
                        <Item key="/admin/gkinds">
                            <a href="javascript:;" onClick={ (e)=>this.gotoPage(e, "/admin/gkinds/list", 'COMMODITY_ CATEGORY') }>
                                { Tr("adminLeftMenu3") }
                            </a>
                        </Item>
                        <Item key="/admin/goodsprop">
                            <a href="javascript:;" onClick={ (e)=>this.gotoPage(e, "/admin/goodsprop/list", 'COMMODITY_ATTRIBUTES') }>
                                { Tr("adminLeftMenu4") }
                            </a>
                        </Item>
                    </SubMenu>
                    <SubMenu key="role" title={<span><i
                        className="icon iconfont icon-yuangongliebiao"> </i><span>{ Tr("adminLeftMenu5") }</span></span>}>
                        <Item key="/admin/staff">
                            <a href="javascript:;" onClick={ (e)=>this.gotoPage(e, "/admin/staff/list", 'EMPLOYEE_LIST') }>
                                { Tr("adminLeftMenu6") }
                            </a>
                        </Item>
                        <Item key="/admin/role">
                            <a href="javascript:;" onClick={ (e)=>this.gotoPage(e, "/admin/role/list", 'EMPLOYEE_STATUS') }>
                                { Tr("adminLeftMenu7") }
                            </a>
                        </Item>
                    </SubMenu>
                    <SubMenu key="store"
                             title={<span><i className="icon iconfont icon-mendian"></i> <span>{ Tr("adminLeftMenu9") }</span></span>}>
                        <Item key="/admin/discount">
                            <a href="javascript:;" onClick={ (e)=>this.gotoPage(e, "/admin/discount/list", 'TAKEAWAY') }>
                                { Tr("adminLeftMenu10") }
                            </a>
                        </Item>
                        <Item key="/admin/store1">
                            <a href="javascript:;" onClick={ (e)=>this.gotoPage(e, "/admin/store1/setting", 'RESTAURANT') }>
                                { Tr("add57") }
                            </a>
                        </Item>
                        <Item key="/admin/aboutus">
                            <a href="javascript:;" onClick={ (e)=>this.gotoPage(e, "/admin/aboutus", 'ABOUT_US') }>
                                { Tr("add170") }
                            </a>
                        </Item>
                        <Item key="/admin/store">
                            <a href="javascript:;" onClick={ (e)=>this.gotoPage(e, `/admin/store/edit/${guid}`, 'STORE_INFO') }>
                                { Tr("adminLeftMenu12") }
                            </a>2
                        </Item>
                        <Item key="/admin/businesshours">
                            <a href="javascript:;" onClick={ (e)=>this.gotoPage(e, `/admin/businesshours`, 'BUSINESS_HOURS') }>
                                { Tr("adminLeftMenu13") }
                            </a>
                        </Item>
                        <Item key="/admin/table">
                            <a href="javascript:;" onClick={ (e)=>this.gotoPage(e, `/admin/table`, 'TABLE_SETTING') }>
                                { Tr("adminLeftMenu14") }
                            </a>
                        </Item>
                        <Item key="/admin/printer">
                            <a href="javascript:;" onClick={ (e)=>this.gotoPage(e, `/admin/printer`, 'BASIC_SETTING') }>
                                { Tr("adminLeftMenu15") }
                            </a>
                        </Item>
                        <Item key="/admin/paysetting">
                            <a href="javascript:;" onClick={ (e)=>this.gotoPage(e, `/admin/paysetting`, 'PAY_SETTING') }>
                                { Tr("payCardSetting1") }
                            </a>
                        </Item>
                    </SubMenu>
                    <Item key="/admin/order" onClick={ ()=>this.setState({ openKeys: [] }) }>
                        <a href="javascript:;" onClick={ (e)=>this.gotoPage(e, `/admin/order/list`, 'ORDER') }>
                            <i className="icon iconfont icon-dingdan"> </i>
                            <span>{ Tr("adminLeftMenu17") }</span>
                        </a>
                    </Item>
                    <Item key="/admin/invoice"  onClick={ ()=>this.setState({ openKeys: [] }) }>
                        <a href="javascript:;" onClick={ (e)=>this.gotoPage(e, `/admin/invoice/list`) }>
                            <i className="icon iconfont icon-fapiao1"> </i>
                            <span>{ Tr("add81") }</span>
                        </a>
                    </Item>

                    <SubMenu key="turnover" title={<span><i
                        className="icon iconfont icon-jine1"> </i><span>{ Tr("bill1") }</span></span>}>
                        <Item key="/admin/turnover/date">
                            <a href="javascript:;" onClick={ (e)=>this.gotoPage(e, `/admin/turnover/date`, 'DAILY_TURNOVER') }>
                                { Tr("bill2") }
                            </a>
                        </Item>
                        <Item key="/admin/turnover/month">
                            <a href="javascript:;" onClick={ (e)=>this.gotoPage(e, `/admin/turnover/month`, 'MONTHLY_TURNOVER') }>
                                { Tr("bill3") }
                            </a>
                        </Item>
                    </SubMenu>

                    <SubMenu key="user" title={<span><i
                        className="icon iconfont icon-yuangongliebiao"> </i><span>{ Tr("add7") }</span></span>}>
                        <Item key="/admin/userlist">
                            <a href="javascript:;" onClick={ (e)=>{
                                window.clearUserQuery && window.clearUserQuery();
                                this.gotoPage(e, `/admin/userlist`, 'USER_LIST');
                            } }>
                                { Tr("add8") }
                            </a>
                        </Item>
                        <Item key="/admin/template">
                            <Link to="/admin/template">
                                { Tr("add83") }
                            </Link>
                        </Item>
                        <Item key="/admin/push">
                            <Link to="/admin/push">
                                { Tr("add84") }
                            </Link>
                        </Item>
                        <Item key="/admin/user/recharge">
                            <Link to="/admin/user/recharge">
                                { Tr("add347") }
                            </Link>
                        </Item>
                        {/*<Item key="/admin/user/report">*/}
                        {/*    <Link to="/admin/user/report">*/}
                        {/*        { Tr("add9") }*/}
                        {/*    </Link>*/}
                        {/*</Item>*/}
                    </SubMenu>
                    <Item key="/admin/dynamic">
                        <a href="javascript:;" onClick={ (e)=>{
                            this.gotoPage(e, `/admin/dynamic/list`);
                        } }>
                            <i className="icon iconfont icon-edit"> </i>
                            <span>{ Tr("ximiadd283") }</span>
                        </a>
                    </Item>
                    <SubMenu key="points" title={<span><i
                        className="icon iconfont icon-4"> </i><span>{ Tr("add325") }</span></span>}>
                        <Item key="/admin/points/detail">
                            <Link to="/admin/points/detail">
                                { Tr("add326") }
                            </Link>
                        </Item>
                        {
                            open_integral ?
                                <Item key="/admin/points/record">
                                    <Link to="/admin/points/record">
                                        { Tr("add327") }
                                    </Link>
                                </Item>
                                : null
                        }
                        {
                            open_integral ?
                                <Item key="/points/setting">
                                    <Link to="/admin/points/setting">
                                        { Tr("add328") }
                                    </Link>
                                </Item>
                                : null
                        }
                    </SubMenu>
                </Menu>
            </div>
        );
    }
}
