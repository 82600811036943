import React, {Component} from "react";
import {Dropdown, Menu, Modal} from 'antd';
import {connect} from "react-redux";
import {Link} from 'react-router-dom';
import {actions} from "./../../../redux/action/common";
import "./index.scss";
import Req from "@util/fetch";
import Tr from "@util/translate";

const language = {
    es: "Español",
    zh: "中文"
}
let adminLeftMenuPermision = [
    'COMMODITY_LIST', "COMMODITY_ CATEGORY", "COMMODITY_ATTRIBUTES",
    "EMPLOYEE_LIST", "EMPLOYEE_STATUS",
    "TAKEAWAY", "RESTAURANT", "ABOUT_US", "STORE_INFO", "BUSINESS_HOURS", "TABLE_SETTING", "BASIC_SETTING", "PAY_SETTING",
    "ORDER",
    "DAILY_TURNOVER", "MONTHLY_TURNOVER",
    "USER_LIST",
];
let urlData = {
    "COMMODITY_LIST": "/admin/goods/list",
    "COMMODITY_ CATEGORY": "/admin/gkinds/list",
    "COMMODITY_ATTRIBUTES": "/admin/goodsprop/list",
    "EMPLOYEE_LIST": "/admin/staff/list",
    "EMPLOYEE_STATUS": "/admin/role/list",
    "TAKEAWAY": "/admin/discount/list",
    "RESTAURANT": "/admin/store1/setting",
    "ABOUT_US": "/admin/aboutus",
    "STORE_INFO": "/admin/store/edit/undefined",
    "BUSINESS_HOURS": "/admin/businesshours",
    "TABLE_SETTING": "/admin/table",
    "BASIC_SETTING": "/admin/printer",
    "PAY_SETTING": "/admin/paysetting",
    "ORDER": "/admin/order/list",
    "DAILY_TURNOVER": "/admin/turnover/date",
    "MONTHLY_TURNOVER": "/admin/turnover/month",
    "USER_LIST": "/admin/userlist",
};

@connect(state => {
        return {
            userInfo: state.global.userInfo || null,
            lang: state.global.lang || "zh"
        };
    },
    dispatch => {
        return {
            langActions: data => {
                dispatch(actions.lang(data));
            }
        };
    })
export default class Header extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.setLanguage();
    }

    componentWillReceiveProps(){
    }
    gotoAdmin = ()=>{
        let url = '';
        adminLeftMenuPermision.forEach((item, index)=>{
            if(window.hasPermisionCode(item) && !url){
                url = urlData[item] + `?times=${new Date().getTime()}`
            }
        })
        if(url){
            window.confrimPasswordAction('admin', ()=>{
                location.hash = url;
            });
        }else{
            alert(Tr("noOpenChashPer"));
        }
    }

    setLanguage = ()=>{
        const storeData = JSON.parse(localStorage.getItem("storeData"));
        Req(
            {
                url: `/v1/account/employeeLang/${storeData.guid || ''}?lang=${this.props.lang === 'zh' ? 'cn' : 'es'}`,
                type: "POST",
                data: {
                    lang: this.props.lang === 'zh' ? 'cn' : 'es'
                }
            },
            res => {
                console.log(res);
            }
        );
    }

    render() {
        const { userInfo } = this.props;
        return (
            <header>
                <div>
                    <a href="javascript:;" onClick={ this.gotoAdmin }>
                        {
                            userInfo.agent && userInfo.agent.is_use_logo ?
                                <img src={ userInfo.agent.logo } alt="" style={{ width: 70,minHeight: 20 }} />
                                :
                                <img src="./public/img/logo.png" alt="" style={{ width: 70,minHeight: 20 }} />
                        }
                    </a>
                </div>

                <Dropdown className="switchLanguage" overlay={
                    <Menu value={ this.props.lang }>
                        <Menu.Item key="0" onClick={() => {
                            localStorage.setItem("lang", 'zh');
                            // this.props.langActions('zh');
                            location.reload();
                        }}>
                            <span>中文</span>
                        </Menu.Item>
                        <Menu.Item key="1" onClick={() => {
                            localStorage.setItem("lang", 'es');
                            // this.props.langActions('es');
                            location.reload();
                        }}>
                            <span>Español</span>
                        </Menu.Item>
                    </Menu>
                } trigger={['click']}>
                    <span>{language[this.props && this.props.lang]}<i
                        className="icon iconfont icon-icon_sanjiaoxing"></i></span>
                </Dropdown>
            </header>
        );
    }
}
