import React, {Fragment} from 'react';
import './index.scss';
import Tr from "@util/translate";
import {Radio, Button, Modal, message} from "antd";
import Req from "@util/fetch";

const confirm = Modal.confirm;

export default class SelectAccount extends React.Component{
    constructor() {
        super();
    }
    state = {
        password: ""
    }

    componentDidMount() {
        const { userList } = this.props;
        document.addEventListener('keydown', this.keyDownAction);
        if(userList && userList.length){
            let currentName = (JSON.parse(localStorage.getItem('storeData')) || {}).name;
            this.selectedAccount(userList[userList[0].name === currentName ? 1 : 0])
        }
    }
    componentWillUnmount() {
        document.removeEventListener('keydown', this.keyDownAction)
    }

    keyDownAction = (e)=>{
        let code = e.keyCode;
        if(code === 13){
            this.loginAction();
        }else if(code === 8){
            this.cancelInput();
        }else if((code >= 48 && code <= 57) || (code>=96 && code<=105)){
            let number = Math.abs(96 - code);
            if(code < 60){
                number = Math.abs(48 - code);
            }
            this.inputNumber(number);
        }
        e.stopPropagation();
        e.preventDefault();
    }

    selectedAccount = (item)=>{
        this.setState({
            loginName: item.loginName
        }, ()=>{
            const { remberPwd, loginName, password } = this.state;

            let passWordInfo = getStoreage('passWordInfo');
            if(passWordInfo){
                passWordInfo = JSON.parse(passWordInfo);
            }else{ passWordInfo = {} }
            if(passWordInfo[loginName]){
                this.setState({
                    remberPwd: true,
                    password: passWordInfo[loginName]
                })
            }else{
                this.setState({
                    remberPwd: false,
                    password: ''
                })
            }
        })
    }

    loginOut = () => {
        confirm({
            title: Tr("leftMenu1"),
            content: Tr("leftMenu2"),
            okText: Tr("sure"),
            cancelText: Tr("cancel"),
            onOk: () => {
                localStorage.removeItem("userInfo");
                localStorage.removeItem("lang");
                window.clearGoodsCache && window.clearGoodsCache();
                location.hash = "/";
            },
            onCancel() {
            }
        });
    };

    remberPwd = (type) => {
        this.setState({
            [type === 2 ? 'remberNumber' : 'remberPwd']: !this.state[type === 2 ? 'remberNumber' : 'remberPwd']
        }, ()=>{
            if(type === 2){
                setStorage('remberNumber', this.state.remberPwd ? 'true' : '');
                return ;
            }
            setStorage('remberPwd', this.state.remberPwd ? 'true' : '');
        });
    };

    inputNumber = (val)=>{
        let { password, loginName } = this.state;

        if(!loginName){
            message.warning(Tr("add163"));
            return ;
        }
        if(password.length<4){
            this.setState({
                password: password + '' + val
            })
        }
    }

    cancelInput = () => {
        const { password } = this.state;

        if (password.length > 0)
            this.setState({password: password.slice(0, -1)})
    }

    loginAction = () => {
        const { loginName, password, storeSerial, remberPwd } = this.state;
        if (!loginName || !password) {
            message.warning(Tr("loginTips"));
            return;
        }
        Req(
            {
                url: "/v1/account/login",
                type: "POST",
                data: {
                    loginName,
                    storeSerial: 'E' + localStorage.getItem("storeSerial2"),
                    password
                },
                loadingTips: Tr('login2')
            },
            res => {
                if (res) {
                    setStorage('remberPwd', remberPwd ? 'true' : '');
                    let passWordInfo = getStoreage('passWordInfo');
                    if(passWordInfo){
                        passWordInfo = JSON.parse(passWordInfo);
                    }else{ passWordInfo = {} }
                    if (this.state.remberPwd) {
                        passWordInfo[loginName] = password;
                        setStorage('passWordInfo', JSON.stringify(passWordInfo));
                    }else{
                        passWordInfo[loginName] = '';
                        setStorage('passWordInfo', JSON.stringify(passWordInfo));
                    }
                    localStorage.setItem("userInfo", JSON.stringify(res.data));
                    Req(
                        {
                            url: `/v1/account`,
                            type: "GET",
                            hideLoading: true
                        },
                        res => {
                            if (res) {
                                localStorage.setItem("storeData", JSON.stringify(res.data));
                                location.reload();
                            }
                        }
                    )
                }
            }
        );
    };
    render(){
        const { loginName, password='', remberPwd } = this.state;
        const { userList=[], close } = this.props;

        let currentName = (JSON.parse(localStorage.getItem('storeData')) || {}).name;

        return (
            <div className={"select-account"}>
                <div className="select-account-content">
                    <div className="user-list">
                        {
                            userList.map(item=>{
                                if(item.name === currentName) return null;
                                return (
                                    <div>
                                        <img src={ item.headImage || '/public/img/defaultuser.jpeg' } onError={
                                            e=>{ e.target.src = '/public/img/defaultuser.jpeg' }
                                        } alt="" className={ loginName === item.loginName ? 'active' : '' }
                                             onClick={ ()=>{ this.selectedAccount(item) } }
                                        /><br/><br/>
                                        { item.name }
                                    </div>
                                )
                            })
                        }
                    </div>
                    <div className="login-container">
                        <div className="number-show-container">
                            {
                                <Fragment>
                                    <div>{ password[0] && '•' || '' }</div>
                                    <div>{ password[1] && '•' || '' }</div>
                                    <div>{ password[2] && '•' || '' }</div>
                                    <div>{ password[3] && '•' || '' }</div>
                                </Fragment>
                            }
                        </div>
                        <div style={{ textAlign: 'right', marginTop: 12, paddingRight: 213 }}>
                            <Radio size="large" checked={ remberPwd } onClick={ this.remberPwd }>{ Tr("remberPwd") }</Radio>
                        </div>
                        <ul className="inputNumber">
                            {["7", "8", "9", "4", "5", "6", "1", "2", "3", "0"].map(
                                val => {
                                    return (
                                        <li
                                            onClick={() => {
                                                this.inputNumber(val);
                                            }}
                                        >
                                            {val}
                                        </li>
                                    );
                                }
                            )}
                            <li onClick={ this.cancelInput }>
                                <img src="/public/img/del.svg" alt=""/>
                            </li>
                        </ul>
                    </div>
                </div>

                <div className="btns">
                    <Button type="default" onClick={ close }>{ Tr("choice62") }</Button>
                    <Button type="primary" onClick={ this.loginOut }>{ Tr("add161") }</Button>
                    <Button className="change" type="default" onClick={ this.loginAction }>{ Tr("add162") }</Button>
                </div>
            </div>
        )
    }
}

function getStoreage(key){
    if(window.getData){
        return window.getData(key);
    }else{
        return localStorage.getItem(key);
    }
}
function setStorage(key, value){
    if(window.setData){
        window.setData(key, value);
    }else{
        localStorage.setItem(key, value);
    }
}