export function global(initState = {orderIndex: 0, dishes_lang: 0}, action) {
    switch (action.type) {
        case "USERINFO":
            return Object.assign({}, initState, {userInfo: action.data});
        case "ALLGOODSLOADING":
            return Object.assign({}, initState, {allGoodsLoading: action.data});
        case "ORDERINDEX":
            return Object.assign({}, initState, {orderIndex: action.data});
        case "LANG":
            return Object.assign({}, initState, {lang: action.data});
        case "DISHES_LANG":
            return Object.assign({}, initState, {dishes_lang: action.data});
        default:
            return initState;
    }
}

export function admin(initState = {}, action) {
    switch (action.type) {
        case "GOODSLIST":
            return Object.assign({}, initState, {goodsList: action.data});
        case "GOODSKINDLIST":
            return Object.assign({}, initState, {goodsKindList: action.data});
        case "STAFFLIST":
            return Object.assign({}, initState, {staffList: action.data});
        case "POSTLIST":
            return Object.assign({}, initState, {postList: action.data});
        case "PROPLIST":
            return Object.assign({}, initState, {propList: action.data});
        case "STORELIST":
            return Object.assign({}, initState, {storeList: action.data});
        case "COUPONSLIST":
            return Object.assign({}, initState, {couponsList: action.data});
        case "BANNERLIST":
            return Object.assign({}, initState, {bannerList: action.data});
        default:
            return initState;
    }
}
