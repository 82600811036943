import { message } from 'antd';
import E from 'wangeditor';
import Tr from "@util/translate";

/**
 * 初始化富文本编辑器
 * @param params
 * {
 *      uploadToken: string  图片上传token
 *      container: element  需要初始化的容器
 *      setEditor: object  设置并存储编辑器对象
 * }
 */
export default async function initEditor(params){
    const { html, container, setEditor } = params;
    const editor = new E(container);
    setEditor(editor);
    editor.customConfig.zIndex = 100;
    editor.customConfig.onchange = (html)=>{};
    editor.customConfig.lang = {
        '设置标题': Tr("editor1"),
        '正文': Tr("editor2"),
        '链接文字': Tr("editor3"),
        '链接': Tr("editor4"),
        '上传图片': Tr("editor5"),
        '上传': Tr("editor6"),
        '创建': Tr("editor7"),
        字号: Tr("editor8"),
        字体: Tr("editor9"),
        宋体: Tr("editor10"),
        微软雅黑: Tr("editor11"),
        文字颜色: Tr("editor12"),
        设置列表: Tr("editor13"),
        有序列表: Tr("editor14"),
        无序列表: Tr("editor15"),
        对齐方式: Tr("editor16"),
        靠左: Tr("editor17"),
        居中: Tr("editor18"),
        靠右: Tr("editor19"),
        插入视频: Tr("editor20"),
        格式如: Tr("editor21"),
    }
    editor.customConfig.menus = [
        'head',  // 标题
        'bold',  // 粗体
        'fontSize',  // 字号
        'fontName',  // 字体
        'italic',  // 斜体
        'underline',  // 下划线
        'strikeThrough',  // 删除线
        'foreColor',  // 文字颜色
        'backColor',  // 背景颜色
        'link',  // 插入链接
        'list',  // 列表
        'justify',  // 对齐方式
        'quote',  // 引用
        // 'emoticon',  // 表情
        'image',  // 插入图片
        // 'table',  // 表格
        'video',  // 插入视频
        // 'code',  // 插入代码
        'undo',  // 撤销
        'redo'  // 重复
    ];
    editor.customConfig.showLinkImg = false;    // 隐藏“网络图片”tab
    editor.customConfig.uploadImgServer = window.serverUrl + '/store/v1/upload';
    editor.customConfig.uploadImgMaxSize = 1024 * 1024;
    editor.customConfig.uploadImgMaxLength = 1;
    editor.customConfig.uploadFileName = 'file';
    editor.customConfig.uploadImgHooks = uploadConfig(this);
    editor.customConfig.uploadImgParams = {
    };
    editor.customConfig.uploadImgHeaders = {};
    editor.customConfig.customAlert = function (info) {
        message.warning(info);
    };//自定义提示方法
    editor.create();
    if(html) editor.txt.html(html);
}
//编辑器上传回调配置

const uploadConfig = (_this)=>{
    let hide = ()=>{};
    return {
        before: function (xhr, editor, files) {
            // 图片上传之前触发
            // xhr 是 XMLHttpRequst 对象，editor 是编辑器对象，files 是选择的图片文件

            // 如果返回的结果是 {prevent: true, msg: 'xxxx'} 则表示用户放弃上传
            // return {
            //     prevent: true,
            //     msg: '放弃上传'
            // }
            hide = message.loading(Tr("uploading"), 0);
        },
        success: function (xhr, editor, result) {
            hide();
            // 图片上传并返回结果，图片插入成功之后触发
            // xhr 是 XMLHttpRequst 对象，editor 是编辑器对象，result 是服务器端返回的结果
        },
        fail: function (xhr, editor, result) {
            hide();
            // 图片上传并返回结果，但图片插入错误时触发
            // xhr 是 XMLHttpRequst 对象，editor 是编辑器对象，result 是服务器端返回的结果
        },
        error: function (xhr, editor) {
            hide();
            // 图片上传出错时触发
            // xhr 是 XMLHttpRequst 对象，editor 是编辑器对象
        },
        timeout: function (xhr, editor) {
            hide();
            // 图片上传超时时触发
            // xhr 是 XMLHttpRequst 对象，editor 是编辑器对象
        },

        // 如果服务器端返回的不是 {errno:0, data: [...]} 这种格式，可使用该配置
        // （但是，服务器端返回的必须是一个 JSON 格式字符串！！！否则会报错）
        customInsert: function (insertImg, result, editor) {
            if(!result.isError){
                insertImg(result.data && result.data.url);
                // 图片上传并返回结果，自定义插入图片的事件（而不是编辑器自动插入图片！！！）
                // insertImg 是插入图片的函数，editor 是编辑器对象，result 是服务器端返回的结果

                // 举例：假如上传图片成功后，服务器端返回的是 {url:'....'} 这种格式，即可这样插入图片：
                // result 必须是一个 JSON 格式字符串！！！否则报错
            }else{
                message.warning(Tr("uploadFail"));
            }
        }
    };
};