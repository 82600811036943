import React, {Component} from "react";
import { Button } from 'antd';
import ReactToPrint from 'react-to-print';
import Tr from "@util/translate";
import "./index.scss";
import Req from "../../../../util/fetch";

const QRCode = require('qrcode.react');

export default class AddDesk extends Component {
    constructor(props) {
        super(props);
        this.state = {
            storeInfo: null
        };
    }

    componentDidMount() {
        this.getStoreDetail();
    }

    getStoreDetail = ()=>{
        Req(
            {
                url: `/v1/store?guid=undefined`,
                type: "GET",
                loadingTips: Tr("searching"),
                hideLoading: true,
                errorTips: Tr("discountList5")
            },
            res => {
                if (res) {
                    this.setState({
                        storeInfo: res.data || {}
                    }, ()=>{
                        this.btn && this.btn.buttonNode && this.btn.buttonNode.click();
                    });
                }
            }
        );
    }

    render() {
        const { storeInfo } = this.state;
        let qrcodeList = JSON.parse(localStorage.getItem("qrcode"))

        let url = ''

        switch (process.env.NODE_ENV) {
            case 'DEV':
                url = window.protocolVar + '//qrcode-dev.g-eat.es'
                break
            case 'STAGING':
                url = window.protocolVar + '//qrcode-dev.g-eat.es'
                break;
            case 'PROD':
                url = window.protocolVar + '//qrcode.g-eat.es'
                break;
            default:
                window.serverUrl = window.protocolVar + "//ottshoppingapi.didigo.es/store";
        }

        return (
            <div>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <ReactToPrint
                        trigger={() => <div style={{ padding: 15 }}><Button ref={ el=>this.btn = el } type="primary">{ Tr("print") }</Button></div>}
                        content={() => this.componentRef}
                    />
                    <Button onClick={ ()=>location.hash='/admin/table' } type="default" style={{ margin: '15px 100px 0 0' }}>{ Tr("choice62") }</Button>
                </div>
                <div className='qrcode-container' ref={ el=>this.componentRef = el }>
                    {
                        qrcodeList && qrcodeList.length !== 0 &&
                        qrcodeList.map((item, index) => {
                            const qrcode_src = `${url}/#/?storeId=${item.storeGuid}&codeType=table&tableGuid=${item.guid}&tableNum=${item.num}${storeInfo && storeInfo.scanCodeStyle && storeInfo.scanCodeStyle.style === 'dark'?`&showtheme1`:''}`
                            console.log(qrcode_src);
                            return (
                                <div className='qrcode' key='index'>
                                    <div className='qrcode-title'>
                                        <div className='left'>
                                            <div className='top'>
                                                MESA
                                            </div>
                                            <div className='bottom'>
                                                {item.tableArea}
                                            </div>
                                        </div>
                                        <div className='right'>
                                            {item.num}
                                        </div>
                                    </div>
                                    <div className='qrcode-img'>
                                        <QRCode value={qrcode_src}/>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        );
    }
}


