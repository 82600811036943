import React from 'react';
import Req from "../../util/fetch";
import './index.scss';

export default class PdfRead extends React.Component{
    constructor() {
        super();
    }
    state = {
        storeConf: {}
    }

    componentDidMount() {
        Req({
            url: `/customer/v1/store?${location.href.split('?')[1] || ''}`,
            type: 'GET',
            hideLoading: true
        }, res=>{
            if(res){
                this.setState({
                    storeConf: res.data && res.data.storeConf || {}
                }, ()=>{
                    const { storeConf } = this.state;
                    if(storeConf.isPdf){
                        location.href = storeConf.pdf;
                    }
                });
            }
        })
    }

    render(){
        const { storeConf } = this.state;
        return (
            <div className="pdf-readr">
                {
                    storeConf.isPdf ? '' : 'La URL que ha visitado no es válida'
                }
            </div>
        )
    }
}