export default {
    login1: "账号和密码不能为空",
    login2: "登录中...",
    login3: "登录",
    login4: "请输入账号",
    login5: "请输入您的商户号",
    login6: "请输入密码",
    login7: "记住账号",
    login8: "忘记密码？",
    login9: "还没有账号？立即注册？",

    register1: "西班牙",
    register3: "获取业务数据出错！",
    register4: "获取支付方式数据出错！",
    register5: "获取地址数据出错！",
    register6: "未找到选定的位置！",
    register7: "请先选择地址",
    register8: "上传出错，请重试!",
    register9: "注册成功，即将跳转到登录页",
    register10: "注册账号",
    register11: "登录账号(必填)",
    register12: "密码(必填)",
    register13: "确认密码(必填)",
    register14: "邮箱地址(必填)",
    register15: "主营（至少选一个）",
    register16: "支付方式",
    register17: "门店名称",
    register18: "联系人",
    register19: "联系电话",
    register20: "人均消费(€)",
    register21: "配送范围(km)",
    register22: "地址(必填)",
    register23: "请选择...",
    register24: "街道地址",
    register25: "请输入街道地址",
    register26: "请输入cif",
    register27: "上传图片",
    register28: "背景图片",
    register29: "上传后的背景图片将用于店铺首页",
    register30: "店铺LOGO",
    register31: "上传店铺LOGO尺寸： 1:1",
    register32: "注册",
    register33: "已有账号？立即登录",
    register34: "请选择使用语言",
    register35: "税号保存后不可修改，",
    register36: "如要修改请询问系统开发商",

    index1: "7日内收款",
    index2: "商品销量榜",
    index3: "份",
    index4: "上一次账期",
    index5: "本周账期",
    index6: "本月账期",
    index7: "订单数",
    index8: "营业额/实收额",
    index9: "当前账期",
    index10: "进行中订单",
    index11: "已完成订单",

    leftMenu1: "确认退出",
    leftMenu2: "是否清除登录信息并退出？",
    leftMenu5: "点餐",
    leftMenu6: "桌位",
    leftMenu7: "报表",
    leftMenu8: "打印订单",

    choice1: "无桌单新动态，来自桌位扫码点餐",
    choice2: "点击查看",
    choice3: "桌新动态，来自店铺扫码点餐",
    choice4: "桌的订单",
    choice5: "新的外卖订单",
    choice6: "点击查看新的外卖订单",
    choice7: "获取订单详情出错!",
    choice8: "获取支付方式出错！",
    choice9: "请选择菜品！",
    choice10: "下单成功！",
    choice11: "确认退单",
    choice12: "是否确认退单？",
    choice13: "退单成功！",
    choice14: "支付成功！",
    choice15: "没有挂单数据",
    choice16: "确认删除",
    choice17: "挂单数据一旦删除将无法恢复，是否确认删除？",
    choice18: "请求中...",
    choice19: "操作成功！",
    choice20: "获取播放声音状态出错！",
    choice21: "离开将自动挂单，确定离开吗？",
    choice22: "点餐",
    choice23: "桌位",
    choice24: "人数",
    choice25: "备注",
    choice26: "请输入备注",
    choice27: "外带",
    choice28: "是",
    choice29: "否",
    choice30: "折扣",
    choice31: "应付",
    choice32: "下单",
    choice33: "支付",
    choice34: "退单",
    choice35: "挂单",
    choice36: "取单",
    choice37: "外卖",
    choice38: "已完成",
    choice39: "订单号",
    choice40: "无",
    choice41: "堂食",
    choice42: "下单时间",
    choice43: "订单",
    choice44: "折扣",
    choice45: "打包",
    choice46: "配送",
    choice47: "送达",
    choice48: "找零",
    choice49: "确认下单",
    choice50: "挂单时间",
    choice51: "金额",
    choice52: "操作",
    choice53: "删除",
    choice54: "暂无挂单数据",
    choice55: "换桌与合桌",
    choice56: "你的浏览器不支持audio标签",
    choice57: "外卖",
    choice58: "下单",
    choice61: "选择骑手",
    choice62: "返回",
    choice63: "确认配送",
    choice64: "请选择骑手",
    choice65: "分配骑手成功",

    changeTab1: "当前为第一页！",
    changeTab2: "当前为最后一页！",
    changeTab3: "请选择要转移/合并的桌号",
    changeTab4: "转移/合并中",
    changeTab5: "当前桌号",
    changeTab6: "转移/合并到",
    changeTab7: "确认快捷键",
    changeTab8: "暂无桌位单",
    changeTab9: "空闲",
    changeTab10: "使用中",
    changeTab11: "取消",
    changeTab12: "确定",
    changeTab13: "请选择桌号",
    changeTab14: "选择预约桌号,系统将在预约期间展示",

    choiceTabs1: "桌位单",
    choiceTabs2: "无桌单",
    choiceTabs3: "外卖单",
    choiceTabs4: "已完成",

    completeted1: "暂无已完成订单",
    completeted2: "订单号",

    deskNo1: "全部",
    deskNo2: "已开单",
    deskNo3: "已锁定",
    deskNo4: "暂无桌位单",
    deskNo5: "人",

    goods1: '名称',
    goods2: '商品种类',
    goods3: '获取商品分类出错！',
    goods4: '获取商品出错！',
    goods5: '请输入菜品名称或编号',
    goods6: '暂无符合条件商品',

    goodsKind1: "",

    noDesk1: "分钟",
    noDesk2: "暂无无桌位",
    noDesk3: "搜索菜品名称",

    pickChange1: "请输入实收金额！",
    pickChange2: "金额不足",
    pickChange3: "实收",
    pickChange4: "应收",
    pickChange5: "找零",
    pickChange6: "打印小票",
    pickChange7: "提示",
    pickChange8: "是否确认结账?",

    shopList1: "是否确认删除商品？",
    shopList2: "请输入退菜理由",
    shopList3: "名称",
    shopList4: "单价",
    shopList5: "数量",
    shopList6: "小计",
    shopList7: "暂无菜品",
    shopList8: "加菜",

    takeaway1: "不接单",
    takeaway2: "接单且外送",
    takeaway3: "接单不外送",
    takeaway4: "获取外卖状态出错！",
    takeaway5: "声音已关闭",
    takeaway6: "声音已打开",
    takeaway7: "暂无外卖单",

    payStatusUn: "未支付",
    payStatusPart: "部分支付",
    payStatusPay: "已支付",
    payStatusPartRefund: "部分退款",
    payStatusRefund: "已退款",

    orderStatus1: "新订单",
    orderStatus2: "已打包",
    orderStatus3: "配送中",
    orderStatus4: "已送达",
    orderStatus5: "已完成",
    orderStatus6: "已结算",
    orderStatus7: "已退订",

    businessHours1: "周一",
    businessHours2: "周二",
    businessHours3: "周三",
    businessHours4: "周四",
    businessHours5: "周五",
    businessHours6: "周六",
    businessHours7: "周日",
    businessHours8: "获取营业时间出错！",
    businessHours9: "保存失败！",
    businessHours10: "保存中...",
    businessHours11: "保存成功！",
    businessHours12: "营业时间设置",
    businessHours13: "上午",
    businessHours14: "下午",
    businessHours15: "休息",

    discountEdit1: "请输入使用门槛",
    discountEdit2: "请输入折扣",
    discountEdit3: "折扣管理",
    discountEdit4: "修改折扣",
    discountEdit5: "添加折扣",
    discountEdit6: "折扣类型",
    discountEdit7: "满减",
    discountEdit8: "按比例折扣",
    discountEdit9: "使用门槛",
    discountEdit10: "折扣金额",
    discountEdit11: "折扣比例",
    discountEdit12: "请输入折扣金额",
    discountEdit13: "请输入折扣比例(1-100)",
    discountEdit14: "网站管理",

    discountList1: "删除成功",
    discountList2: "修改优惠状态失败！",
    discountList3: "修改优惠状态成功！",
    discountList4: "获取支付方式数据出错！",
    discountList5: "获取门店资料失败！",
    discountList6: "修改成功！",
    discountList7: "获取外卖状态出错！",
    discountList8: "外卖管理",
    discountList9: "外卖开关设置",
    discountList10: "开启外卖",
    discountList11: "开启配送",
    discountList12: "基础设置",
    discountList13: "支付方式(外卖)",
    discountList14: "外卖起送金额(€)",
    discountList15: "配送范围",
    discountList16: "配送距离",
    discountList17: "配送费",
    discountList18: "距离",
    discountList19: "新增配送费设置",
    discountList20: "区号配送",
    discountList21: "添加折扣",
    discountList22: "折扣金额/比例",
    discountList23: "管理",
    discountList24: "冻结",
    discountList25: "解冻",
    discountList26: "暂无折扣数据",
    discountList27: "请填写区号",
    discountList28: "满减",
    discountList29: "按比例折扣",
    discountList30: "正常",
    discountList31: "关于我们",
    discountList32: "您未配置支付，请联系系统管理员",
    discountList33: "开启指派骑手功能",
    discountList34: "关闭配送后，网站会显示下面文字",
    discountList35: "网站域名",
    discountList36: "新增",
    discountList37: "网站首页滚动图片",
    discountList38: "暂无数据",

    bannerEdit1: "添加滚动图",
    bannerEdit2: "编辑滚动图",
    bannerEdit3: "名称",
    bannerEdit4: "请输入名称",
    bannerEdit5: "显示时间",
    bannerEdit6: "长期",
    bannerEdit7: "图片",
    bannerEdit8: "链接地址",
    bannerEdit9: "请输入链接地址",
    bannerEdit10: "跳转到",
    bannerEdit11: "分类页面",
    bannerEdit12: "单个商品",
    bannerEdit13: "请输入名称",
    bannerEdit14: "请选择显示时间",
    bannerEdit15: "请上传图片",
    bannerEdit16: "开始时间",
    bannerEdit17: "结束时间",
    bannerEdit18: "删除",
    bannerEdit19: "修改图片状态成功",
    bannerEdit20: "建议尺寸",
    bannerEdit21: "使用中",
    bannerEdit22: "未开始",
    bannerEdit23: "已结束",
    bannerEdit24: "确认删除该图片吗",
    bannerEdit25: "是",
    bannerEdit26: "否",
    goodsEdit1: "菜品编号",
    goodsEdit2: "菜品",
    goodsEdit3: "获取商品详情失败！",
    goodsEdit4: "获取单位信息失败！",
    goodsEdit5: "获取商品分类信息失败！",
    goodsEdit6: "获取过敏原信息失败！",
    goodsEdit7: "获取商品属性信息失败！",
    goodsEdit8: "确认删除菜品吗？",
    goodsEdit9: "配菜一旦删除将无法恢复，是否确认删除？",
    goodsEdit10: "商品编辑",
    goodsEdit11: "添加商品",
    goodsEdit12: "商品名称",
    goodsEdit13: "请输入商品名称",
    goodsEdit14: "编号",
    goodsEdit15: "请输入商品编号",
    goodsEdit16: "图片",
    goodsEdit17: "商品价格",
    goodsEdit18: "请输入商品价格",
    goodsEdit19: "商品外卖价格",
    goodsEdit20: "请输入商品外卖价格",
    goodsEdit21: "商品单位",
    goodsEdit22: "排序",
    goodsEdit23: "请输入排序",
    goodsEdit24: "分类",
    goodsEdit25: "商品描述",
    goodsEdit26: "类型",
    goodsEdit27: "常规",
    goodsEdit28: "套餐",
    goodsEdit29: "商品禁用后前端将无法显示和点餐",
    goodsEdit30: "商品属性",
    goodsEdit31: "过敏原",
    goodsEdit32: "添加配菜名称",
    goodsEdit33: "删除配菜",
    goodsEdit34: "至少添加",
    goodsEdit35: "个菜",
    goodsEdit36: "选择配菜",
    goodsEdit37: "请输入名称或编号",
    goodsEdit38: "添加配菜",
    goodsEdit39: "排序",
    goodList1: "修改商品状态失败！",
    goodList2: "修改状态成功！",
    goodList3: "设置排序失败！",
    goodList4: "请先选择文件！",
    goodList5: "推荐商品失败！",
    goodList6: "推荐商品成功！",
    goodList7: "取消推荐商品失败！",
    goodList8: "取消推荐商品成功！",
    goodList9: "餐厅",
    goodList10: "所有餐厅",
    goodList11: "分类",
    goodList12: "商品标题",
    goodList13: "批量设置排序",
    goodList14: "导入商品",
    goodList15: "下载模版",
    goodList16: "推荐",
    goodList17: "单位",
    goodList18: "未推荐",
    goodList19: "已推荐",
    goodList20: "取消推荐",
    goodList21: "选择文件",
    goodList22: "外卖价",
    goodList23: "堂食价",
    propEdit1: "属性列表",
    propEdit2: "属性修改",
    propEdit3: "添加属性",
    propEdit4: "属性名称",
    propEdit5: "请输入属性名称",
    propEdit6: "添加岗位",
    propList1: "商品属性列表",
    propList2: "暂无属性",
    goodsKindsEdit1: "分类编辑",
    goodsKindsEdit2: "添加分类",
    goodsKindsEdit3: "分类名称",
    goodsKindsEdit4: "请输入分类名称",
    goodsKindsEdit5: "商品禁用后前端将无法显示此分类下所有商品",
    goodsKindsList1: "商品分类一旦删除将无法恢复，是否确认删除？",
    goodsKindsList2: "修改分类状态失败！",
    goodsKindsList3: "修改分类状态成功！",
    goodsKindsList4: "暂无分类",
    adminLeftMenu1: "商品管理",
    adminLeftMenu2: "商品列表",
    adminLeftMenu3: "商品分类",
    adminLeftMenu4: "商品属性",
    adminLeftMenu5: "员工管理",
    adminLeftMenu6: "员工列表",
    adminLeftMenu7: "角色管理",
    adminLeftMenu8: "岗位管理",
    adminLeftMenu9: "门店设置",
    adminLeftMenu10: "外卖管理",
    adminLeftMenu11: "关于我们",
    adminLeftMenu12: "门店信息",
    adminLeftMenu13: "营业时间设置",
    adminLeftMenu14: "桌位设置",
    adminLeftMenu15: "基础设置",
    adminLeftMenu16: "修改密码",
    adminLeftMenu17: "订单管理",
    orderList1: "获取详情中...",
    orderList2: "用户电话",
    orderList3: "至",
    orderList4: "订单类型",
    orderList5: "订单状态",
    orderList6: "支付状态",
    orderList7: "单号",
    orderList8: "地址",
    orderList9: "联系电话",
    orderList10: "份数",
    orderList11: "总价",
    orderList12: "查看",
    orderList13: "暂无订单",
    orderList14: "订单详情",
    orderList15: "桌号",
    orderList16: "联系人",
    orderList17: "电话",
    orderList18: "付款信息",
    orderList19: "调度信息",
    orderList20: "订单明细",
    orderList21: "合计",
    orderList22: "客户备注",
    orderList23: "删除",
    orderList24: "是否确认删除",
    orderList25: "删除成功",
    postEdit1: "岗位列表",
    postEdit2: "岗位修改",
    postEdit3: "添加岗位",
    postEdit4: "岗位编号",
    postEdit5: "请输入岗位编号",
    postEdit6: "岗位名称",
    postEdit7: "请输入岗位名称",
    postList1: "暂无岗位",
    printer1: "设置声音出错！",
    printer2: "声音开关",
    printer3: "打印设置",
    printer4: "下单 自动打印",
    printer5: "结账 自动打印",
    printer6: "加菜 自动打印",
    printer7: "总单",
    printer8: "小票1",
    printer9: "小票2",
    printer10: "小票3",
    printer11: "小票4",
    printer12: "获取打印机配置出错！",
    printer13: "打印份数",
    printer14: "请选择打印对象",
    printer15: "选择模版",
    printer16: "堂食打印",
    printer17: "外卖打印",
    printer18: "扫码打印",
    printer19: "外卖单",
    printer20: "吧台单",
    printer21: "后厨/加菜单",
    printer22: "对账单",
    printer23: "编号",
    printer24: "添加中...",
    printer25: "添加成功",
    printer26: "修改打印机名称成功",
    printer27: "是否确认删除该打印机？",
    printer28: "删除中...",
    printer29: "提交中...",
    printer30: "修改模板成功！",
    printer31: "保存设置成功！",
    printer32: "设置打印机成功",
    printer33: "打印机列表",
    printer34: "修改名字",
    printer35: "添加打印机",
    printer36: "打印设置",
    printer37: "打印名称",
    printer38: "设置",
    printer39: "打印模版设置",
    printer40: "模版名称",
    printer41: "保存设置",
    printer42: "修改打印机名称",
    printer43: "打印机类型",
    printer44: "本地打印机",
    printer45: "网络打印机",
    printer46: "选择打印机",
    printer47: "打印机名称",
    printer48: "编码",
    printer49: "密钥",
    printer50: "GPRS数据",
    printer51: "选择小票打印内容",
    printer52: "抬头信息",
    printer53: "餐厅名称",
    printer54: "街道名称",
    printer55: "餐厅电话",
    printer56: "餐厅税号",
    printer57: "日期",
    printer58: "票脚信息",
    otherPrinter1: '保存设置失败',
    otherPrinter2: '保存设置成功',
    otherPrinter3: '打印目录',
    otherPrinter4: '名称',
    otherPrinter5: '操作',
    otherPrinter6: '日结账',
    otherPrinter7: '结账时自动打印',
    otherPrinter8: '包括订单列表',
    otherPrinter9: '保存设置',
    roleEdit1: "角色列表",
    roleEdit2: "角色修改",
    roleEdit3: "添加角色",
    roleEdit4: "角色名称",
    roleEdit5: "请输入角色名称",
    roleEdit6: "权限编辑",
    roleList1: "暂无角色",
    staffEdit1: "获取岗位列表出错",
    staffEdit2: "获取角色列表出错",
    staffEdit3: "请等待图片上传完毕",
    staffEdit4: "员工修改",
    staffEdit5: "添加员工",
    staffEdit6: "登录名",
    staffEdit7: "请输入登录名",
    staffEdit8: "员工姓名",
    staffEdit9: "请输入员工姓名",
    staffEdit10: "员工工号",
    staffEdit11: "请输入员工工号",
    staffEdit12: "手机号码",
    staffEdit13: "请输入员工手机",
    staffEdit14: "登录密码",
    staffEdit15: "请输入登录密码",
    staffEdit16: "请输入至少6位密码，必须包含数字、字母、特殊字符(不含4位连续字符)",
    staffEdit17: "头像",
    staffEdit18: "角色",
    staffEdit19: "岗位",
    staffEdit20: "使用语言",
    staffEdit21: "是否是骑手",
    staffList1: "修改员工状态失败！",
    staffList2: "修改员工状态成功！",
    staffList3: "员工电话",
    staffList4: "请输入员工电话",
    staffList5: "修改当前账号密码",
    staffList6: "暂无员工数据",
    updatePwd1: "修改成功,请重新登录!",
    updatePwd2: "原密码",
    updatePwd3: "新密码",
    updatePwd4: "确认密码",
    updatePwd5: "请确认密码",
    aboutus1: "请上传封面",
    aboutus2: "关于我们",
    editor1: "设置标题",
    editor2: "正文",
    editor3: "链接文字",
    editor4: "链接",
    editor5: "上传图片",
    editor6: "上传",
    editor7: "创建",
    editor8: "字号",
    editor9: "字体",
    editor10: "宋体",
    editor11: "微软雅黑",
    editor12: "文字颜色",
    editor13: "设置列表",
    editor14: "有序列表",
    editor15: "无序列表",
    editor16: "对齐方式",
    editor17: "靠左",
    editor18: "居中",
    editor19: "靠右",
    editor20: "插入视频",
    editor21: "格式如",
    storeEdit1: "获取服务数据出错",
    storeEdit2: "资料修改",
    storeEdit3: "门店名称",
    storeEdit4: "服务包括",
    storeEdit5: "APP背景图片",
    storeEdit6: "上传后的背景图片将用于APP店铺首页",
    storeEdit7: "外卖首页Banner",
    storeEdit8: "上传后的背景图片将用于外卖首页",
    storeEdit9: "商家二维码",
    storeEdit10: "推荐给用户，扫描关注您的店铺",
    storeList1: "门店列表",
    storeList2: "请输入餐厅名称",
    storeList3: "请输入手机",
    storeList4: "注册时间",
    storeList5: "餐厅地址",
    storeList6: "暂无餐厅",
    tableManage1: "请先选择一个区域！",
    tableManage2: "新增区域",
    tableManage3: "新增桌位",
    addArea1: "添加区域成功",
    addArea2: "删除区域后不可恢复，是否确认删除？",
    addArea3: "删除区域成功！",
    addArea4: "修改区域",
    addArea5: "新增区域",
    addArea6: "区域名称",
    addArea7: "排列优先级",
    addArea8: "优先级越大，越在上方",
    addArea9: "桌子二维码",
    addArea10: "批量打印该区域二维码",
    addArea11: "完成",
    addDesk1: "修改桌位",
    addDesk2: "新增桌位",
    addDesk3: "桌位名称",
    addDesk4: "可坐人数",
    addDesk5: "桌子二维码",
    addDesk6: "打印该桌二维码",
    download: "下载",
    print: "打印",
    name: "名称",
    time: "时间",
    delConfirm: "删除后不可恢复，是否确认？",
    no: "序号",
    checkAll: "全选",
    all: "全部",
    goods: "商品",
    enable: "启用",
    disable: "禁用",
    upload: "上传",
    uploadTips: "上传出错，请重试!",
    uploadFail: "上传失败！",
    uploadSuccess: "上传成功！",
    uploading: "上传中...",
    tips: "提示",
    saveSuccess: "保存成功！",
    status: "状态",
    edit: "修改",
    edit2: "编辑",
    save: "保存",
    sure: "确定",
    cancel: "取消",
    searching: "查询中...",
    search: "搜索",
    delete: "删除",
    loginOut: "退出",
    goodsAttributes1: "请先选择菜品属性！",
    goodsAttributes2: "选择套餐",
    goodsAttributes3: "请选择",
    goodsAttributes4: "个",
    goodsAttributes5: "请选择菜品属性",
    goodsAttributes6: "修改菜品",
    goodsAttributes7: "输入数量",
    goodsAttributes8: "修改菜品数量",
    goodsAttributes9: "确认修改",
    goodsAttributes10: "加菜",
    goodsAttributes11: "减菜",
    goodsAttributes12: "请选择对应数量的菜品",
    passwordTips: "密码只能为4位数字",
    conTactEmail: '忘记商家号 请联系邮箱： info@g-eat.es',
    enter: "进入",
    inputTips: '请输入由“西米团”发至您邮箱的“商家号”',
    remberPwd: "记住密码",
    inputNumber: "请输入商家号",
    inputNumberAll: "请输入完整的商家号!",
    loginTips: '请选择登录账号并输入密码',
    serachuser: '查询用户中...',
    regsuccess: '注册成功',
    regsuccesstips: "恭喜您已成功注册“西米团”客户端",
    storeNoIs: "您的商家号是",
    loginNow: "立即登录",
    freeReg: "免费注册",
    email: "电子邮件",
    emailUseful: "邮件接收\"商家号\"和\"找回密码\"",
    loginPwd: "登录密码",
    inoutPwdNum: "输入四位数字",
    inoutPwdAgain: "再次输入密码",
    countryprovince: "国家省份",
    country: "国家",
    province: "省份",
    inputAddress: "请输入详细街道、号码",
    inputYoubian: "请填写邮编号码",
    youbian: "邮编号码",
    inputCityName: "请填写城市名字",
    cityName: "城市",
    storenum: "商家号",
    price: '价格',
    goodstax: '菜品税率',
    goodstaxtips: '请输入菜品税率',
    ifTakeway: '是否外卖',
    is: '是',
    not: '否',
    taxonlynumber: '税率只能为数字',
    basicManange: "基础管理",
    takeawayuser1: "添加用户成功!",
    takeawayuser2: "确认选择",
    takeawayuser3: "添加用户",
    takeawayuser4: "用户名",
    takeawayuser5: "街道名",
    takeawayuser6: "街道号",
    takeawayuser7: "单元",
    takeawayuser8: "楼梯",
    takeawayuser9: "楼层",
    takeawayuser10: "门牌号",
    takeawayuser11: "邮编",
    takeawayuser12: "用户性别",
    takeawayuser13: "男",
    takeawayuser14: "女",
    takeawayuser15: "搜索已有用户",
    takeawayuser16: "没有",
    takeawayuser17: "搜索结果",
    editNumNotZero: "菜品数量不能为零",
    bill1: "营业额管理",
    bill2: "日营业额",
    bill3: "月营业额",
    bill4: "订单数量（堂食）",
    bill5: "订单数量（外卖）",
    bill6: "营业额",
    bill7: "现金",
    bill8: "信用卡",
    bill9: "其它",
    bill10: "结账员",
    bill11: "结账时间",
    bill12: "日营业额管理列表",
    bill13: "月营业额管理列表",
    bill14: "选择日期",
    bill15: "暂无数据",
    bill16: "日结账",
    bill17: "日结账打印",
    bill18: "当前尚未开帐",
    bill19: "结账成功",
    bill20: "请输入预备金额",
    bill21: "录入预备金额成功",
    bill22: "订单号",
    bill23: "桌号/电话",
    bill24: "客户",
    bill25: "人数",
    bill26: "金额",
    bill27: "结账方式",
    bill28: "收银员",
    bill29: "结账时间",
    bill30: "销售",
    bill31: "支票",
    bill32: "总金额",
    bill33: "收银机预备",
    bill34: "预备零钱",
    bill35: "开支",
    bill36: "总开支",
    bill37: "总结",
    bill38: "收银机现金",
    bill39: "确认结账时自动打印",
    bill40: "开帐时间",
    bill41: "结账员",
    bill42: "结账时间",
    bill43: "尚未开帐",
    bill44: "确认结账",
    bill45: "预备金额",
    bill46: "预留金额录入",
    bill47: "预备零钱",
    bill48: "打印预备零钱小票",
    bill49: "主营（只可选一个）",
    saleProductDay:"今日",
    saleProductWeek:"本周",
    saleProductMonth:"本月",
    saleProductAll:"全部日期",
    noSaleRangeData:"暂无数据",
    getLocationLoading: "获取位置中",
    getLocationFailure: "获取定位失败",
    selectPrinterModel: "请选择打印机型号",
    printerModel: "打印机型号",
    openChash: "开钱箱",
    noOpenChashPer: "无权限，请联系管理员",
    changePrice: '修改价格',
    editPriceNotZero: "请填写菜品价格",
    printByStep: "分段打印",
    payCardSetting1: "支付设置",
    payCardSetting2: "付款方式",
    payCardSetting3: "开始日期",
    payCardSetting4: "支付金额",
    payCardSetting5: "含税",
    payCardSetting6: "不含税",
    payCardSetting7: "添加银行卡信息",
    payCardSetting8: "银行卡号",
    payCardSetting9: "过期日期",
    payCardSetting10: "安全码",
    payCardSetting11: "信用卡持有人",
    payCardSetting12: "goeat不会保存您的信用卡信息",
    payCardSetting13: "goeat支付协议",
    payCardSetting14: "我已经阅读geat公司的自动扣款协议",
    payCardSetting15: "1个月",
    payCardSetting16: "3个月",
    payCardSetting17: "半年(6个月)",
    payCardSetting18: "1年(12个月)",
    payCardSetting19: "2年(24个月)",
    payCardSetting20: "收银机",
    payCardSetting21: "外卖网站",
    payCardSetting22: "用户app",
    payCardSetting23: "商家app",
    payCardSetting24: "扫码点餐",
    payCardSetting25: "正在使用",
    payCardSetting26: "暂未开通",
    payCardSetting27: "申请开通",
    payCardSetting28: "您可以使用以下服务",
    payCardSetting29: "该功能暂未开放",
    payCardSetting30: "请先阅读并同意扣款协议！",
    payCardSetting31: "请先完善银行卡信息！",
    payCardSetting32: "修改银行卡信息",
    payCardSetting33: "确认添加",
    payCardSetting34: '返回上一页',
    payCardSetting35: "添加信用卡",
    payCardSetting36: "修改信用卡",
    payCardSetting37: "如需取消授权，请联系goeat管理员",
    payCardSetting38: "我已经阅读geat公司的",
    payCardSetting39: "自动扣款协议",
    payCardSetting40: "付款周期",
    payCardSetting41: "个月",
    payCardSetting42: "开始日期",
    payCardSetting43: "支付金额",
    payCardSetting44: "含税",
    payCardSetting45: "不含税",
    payCardSetting46: "ipad点餐",
    payCardSetting47: "添加银行卡信息",
    payCardSetting48: "您还未添加任何信用卡信息",
    payCardSetting49: "添加信用卡",
    payCardSetting50: "当前状态",
    payCardSetting51: "历史记录",
    payCardSetting52: "订单日期",
    payCardSetting53: "订单号",
    payCardSetting54: "持卡人名称",
    payCardSetting55: "支付方式",
    payCardSetting56: "金额",
    payCardSetting57: "支付日期",
    payCardSetting60: "您已经成功 设置信用卡支付",
    payCardSetting61: "持卡人姓名",
    payCardSetting62: "取消支付",
    bookOrder1: "的预订",
    bookOrder2: "您有新的预订订单",
    siteKeyWords: "网站搜索关键词",
    keywords: "关键词",
    description: "描述",
    startNav: "开始导航",
    openBook: "启用预定功能",
    showType: "网站商品显示方式",
    showList: "列表、小图片",
    showImg: "大图片",
    article1: "文章管理",
    article2: "新增方案",
    article3: "方案标题",
    article4: "最后保存日期",
    article5: "是否发布",
    article6: "标题",
    add1: "自取起订金额",
    add2: "接单",
    add3: "用户自取",
    add4: "制作完成",
    add5: "多个关键词用 英文 \",\" 隔开",
    add6: "清空",
    add7: "用户管理",
    add8: "用户列表",
    add9: "用户报表",
    add10: "用户订单",
    add11: "用户详情",
    add11a: "开始制作",
    user1: "禁用后用户将不可下单，是否禁用？",
    user2: "用户姓名",
    user3: "请输入用户姓名",
    user4: "电话",
    user5: "请输入用户电话",
    user6: "查询",
    user7: "导出用户列表Excel",
    user8: "邮件",
    user9: "下单次数",
    user10: "禁用",
    user11: "正常",
    user12: "暂无用户列表",
    user13: "解禁",
    user14: "用户列表",
    user15: "导出出错！",
    user16: "添加预定+",
    user17: "预约已过期",
    user18: "进行中",
    user19: "用户留言的内容",
    user20: "结束（完成）",
    user21: "姓名",
    user22: "邮箱",
    user23: "留言",
    user24: "请选择预约桌号",
    user25: "邮箱格式不正确",
    user26: "手机格式不正确",
    user27: "请填入完整的信息",
    user28: "确认完成此预定吗？",
    user29: "确认取消此预定吗？",
    user30: "用户留言的内容",
    user31: "接受预定",
    user32: "新预约的订单",
    choiceTabs5: "预定",
    choice59:"取消订单",
    choice60: "确认取消该订单",
    add12: "扫码点餐",
    add13: "用户数",
    add14: "月增长数",
    add15: "同比",
    add16: "日活跃量",
    add17: "关注餐厅人数",
    add18: "堂食单总量",
    add19: "本月单量",
    add20: "日均单量",
    add21: "外卖单总量",
    add22: "用户消费排名",
    add23: "按金额",
    add24: "按次数",
    add25: "查看更多",
    add26: "用户名",
    add27: "用户账号",
    add28: "消费次数",
    add29: "消费金额",
    add30: "菜品销售排名",
    add31: "日均销售",
    add32: "销售份数",
    add33: "销售金额",
    add34: "图片",
    add35: "菜名",
    add36: "日均销售(份)",
    add37: "收起",
    add38: "最新消息",
    add39: "新功能",
    add40: "行业消息",
    add41: "已查看",
    add42: "查看",
    add43: "商家统计",
    add45: "发布详情",
    add46: "自助价格",
    add47: "自助餐",
    add48: "菜品使用价格",
    add49: "成人价格/每人",
    add50: "儿童价格/每人",
    add51: "每人每轮可点几个菜？(不设置，将不限制)",
    add52: "每桌多少轮？(不设置，将不限制)",
    add53: "不设置，将不限制",
    add54: "设置成功!",
    add55: "欧元",
    add56: "使用价格",
    add57: "餐厅管理",
    add58: "呼叫服务员",
    add59: "用户结账",
    add60: "配送时间",
    add61: "配送方式",
    add62: "配送",
    add63: "用户自取",
    add64: "收银模式",
    add65: "修改客户人数",
    add66: "成人",
    add67: "儿童",
    add68: "请至少输入一个人数",
    add69: "请先输入用餐人数",
    add70: "本轮只可点",
    add71: "个菜",
    add72: "开通自助餐服务",
    add73: "无权限开钱箱，请联系管理员",
    add74: "无权限开单，请联系管理员",
    add75: "请不要在同一区域内移动桌位",
    add76: "Facebook像素",
    add77: "请输入Facebook像数内容",
    add78: "启用邮箱",
    add79: "预约",
    add80: "商家推广",
    add81: "发票管理",
    add82: "发票号/税号/电话",
    weekNum: "一周",
    monthNum: "一个月",
    threeMonthNum: "三个月",
    sixMonthNum: "六个月",
    noOrderNum: "从未",
    add83: "邮件模板",
    add84: "邮件推送",
    add85: "关键字",
    add86: "请输入关键字",
    add87: "模板编辑",
    add88: "新增模板",
    add89: "请输入标题",
    add90: "推送",
    add91: "内容",
    add92: "标题",
    add93: "模板",
    add94: "推送成功！",
    add95: "请选择模板并完善推送信息",
    add96: "保存成功!",
    add97: "创建时间",
    add98: "更新",
    add99: "总用户数量",
    add100: "未下单用户统计",
    add101: "推广",
    add102: "发送目标",
    add103: "成功总数",
    add104: "失败总数",
    add105: "发送时间",
    add106: "推送详情",
    add107: "公司名称/个人姓名",
    add108: "发票信息",
    add109: "转为发票",
    add110: "推送成功",
    add111: "发票",
    add112: "发票号",
    add113: "是否需要发票",
    add114: "发票信息",
    add115: "客户信息",
    add116: "搜索/添加用户",
    add117: "请先填写发票地址信息",
    add118: "请完善发票地址信息",
    add119: "感谢惠顾",
    add120: "转换成功",
    add121: "打印发票",
    add122: "收入金额",
    add123: "包括税",
    add124: "总计",
    add125: "税额",
    add126: "税前价格",
    add127: "商品号",
    add128: "地址信息",
    add129: "允许不注册下单",
    add130: "选择时间送餐",
    add131: "开启后 允许用户下单时选择配送时间/取餐时间",
    add132: "开启网页导航",
    add133: "设置中还未开启自助餐功能，现在开启自助餐功能？",
    add134: "去开启",
    add135: "菜品使用价格",
    add136: "转为发票后不可撤销，是否继续？",
    add137: "记住商家号",
    add138: "货号",
    add139: "节假日价格设置",
    add140: "按轮数",
    add141: "按总盘数",
    add142: "每人最多可点数量（不限制下单次数）",
    add143: "节假日设置",
    add144: "周一",//周一
    add145: "周二",
    add146: "周三",
    add147: "周四",
    add148: "周五",
    add149: "周六",
    add150: "周日",
    monthName_1: "一月",
    monthName_2: "二月",
    monthName_3: "三月",
    monthName_4: "四月",
    monthName_5: "五月",
    monthName_6: "六月",
    monthName_7: "七月",
    monthName_8: "八月",
    monthName_9: "九月",
    monthName_10: "十月",
    monthName_11: "十一月",
    monthName_12: "十二月",
    add151: "确认选择",
    add153: "上一个月",
    add154: "下一个月",
    add155: "您已选择了",
    add156: "个节假日",
    add157: "点击日历设置",
    add158: "已选择节假日日期：",
    add159: "请选择时间",
    add160: "切换账号或退出登录",
    add161: "退出登录",
    add162: "切换账号",
    add163: "请选择账号",
    add164: "不受限分类",
    add165: "请选择不计算的菜品分类（不设置，将计算在盘数里）",
    add166: "帮助中心",
    add167: "注册协议",
    add168: "网站版权",
    add169: "请输入版权说明",
    add170: "文案设置",
    add171: "最多可点",
    add172: "个菜",
    add173: "轮",
    add174: "预约折扣",
    add175: "预定折扣",
    add176: "输入显示折扣",
    add177: "开启后显示预定折扣",
    add178: "请输入预定折扣",
    add179: "时区",
    add180: "选择餐厅所在时区",
    add181: "Logo图片",
    add182: "外卖网站二维码",
    add183: "二维码介绍",
    add184: "图片上传中",
    add185: "餐期价格设置",
    add186: "不设置餐期价格，系统默认为单人价格1",
    add187: "默认菜品价格",
    add188: "单人价格",
    add189: "成人价格",
    add190: "儿童价格",
    add191: "结算时间",
    add192: "导出Excel",
    add193: "街道列表",
    add194: "已创建",
    add195: "条街道信息",
    add196: "添加街道名称",
    add197: "确认",
    add198: "删除后不可恢复，是否确认删除?",
    add199: "设置排序",
    add200: "排序",
    add201: "开启/关闭 普通点餐功能",
    add202: "PDF菜单",
    add203: "用户扫码只看pdf菜单无法自行点餐",
    add204: "将文件拖到此处，或",
    add205: "点击上传",
    add206: "请上传pdf文件",
    add207: "下载二维码",
    add208: "只能上传PDF文件，且不超过3M",
    add209: "开启/用户扫码 自行点餐。  关闭/用户扫码只看菜单不可点餐",
    add210: "开启/关闭点餐",
    add211: "自助餐和普通点餐服务至少开启一个！",
    add212: "今日",
    add213: "近一周",
    add214: "近一个月",
    add215: "近半年",
    add216: "近一年",
    add217: "网站主题颜色",
    add218: "自定义颜色",
    add219: "余",
    add220: "外卖订单暂不支持分开支付",
    add221: "当前未打开订单",
    add222: "钱箱",
    add223: "菜品已支付完",
    add224: "当前已选择要支付的菜品",
    add225: "支付金额低于应收金额",
    add226: "送厨",
    add227: "修改数量",
    add228: "出单后修改",
    add229: "未找到默认商品",
    add230: "扫码点餐限制",
    add231: "公告",
    add232: "开启后，网站主页面会显示下面文字",
    add233: "今日订单",
    add234: "送餐时间",
    add235: "邮箱密码",
    add236: "明文",
    add237: "查看订单",
    add238: "本店",
    add239: "用户备注",
    add240: "批量删除",
    add241: "必须添加",
    add242: "打印顺序",
    add243: "按菜品编号",
    add244: "按点餐顺序",
    add245: "这里选择打印机后，分类中设置的打印不再生效",
    add246: "添加时间",
    add247: "请先保存当前编辑的属性！",
    add248: "批量修改商品",
    add249: "当前商品尚未修改",
    add250: "点餐 开/关",
    add251: "自助餐 开/关",
    add252: "小号",
    add253: "中号",
    add254: "大号",
    add255: "选择字体大小",
    add256: "请输入密码",
    add257: "始终要密码",
    add258: "下载模板",
    add259: "导入用户",
    add260: "消费总额",
    add261: "最后消费日期",
    add262: "预约单",
    add263: "新预定",
    add264: "已接单",
    add265: "已拒绝",
    add266: "已取消",
    add267: "允许折扣",
    add268: "是否允许用户下单",
    add269: "关闭后用户只可以扫码点餐到购物车，不可发送订单",
    add270: "送达时间",
    add271: "添加菜品",
    add272: "只本机打印",
    add273: "设置页面",
    add274: "点击后可以设置，隐藏不需要的地址信息。在信息后取消打√表示隐藏",
    add275: "取消信息后面勾选表示隐藏",
    add276: "外卖网站与收银台折扣同步",
    add277: "无法获取打印机列表",
    add278: "未找到指定的打印机",
    add279: "不计算 不允许折扣的商品",
    add280: "预定日期",
    add281: "预定人数",
    add282: "自助不允许根据菜品支付",
    add283: "该订单不可按菜品支付",
    add284: "请根据菜品支付",
    add285: "请先选择菜品或设置应收",
    add286: "已选",
    add287: "套餐不可修改",
    add288: "超过限制数量",
    add289: "套餐数量",
    add290: "未找到打印机",
    add291: "该菜品不存在",
    add292: "未找到菜品信息",
    add293: "多份",
    add294: "套餐菜品",
    add295: "按分类排序",
    add296: "公司名称",
    add297: "备注：与菜品一起打印",
    add298: "选择为网站首页",
    add299: "网站导航",
    add300: "菜品下单",
    add301: "设置常用运费",
    add302: "请输入运费",
    add303: "运费",
    add304: "网站语言",
    add305: "西班牙语",
    add306: "意大利语",
    add307: "葡萄牙语",
    add308: "英语",
    add309: "法语",
    add310: "德语",
    add311: "中文",
    add312: "语言",
    add313: "请先启用语言",
    add314: "前往",
    add315: "多语言",
    add316: "其他打印设置",
    add317: "地址/桌号",
    add318: "未收金额",
    add319: "实收额",
    add320: "电话、订单号搜索",
    add321: "扫码声明",
    add322: "扫码点餐网站上的法律说明",
    add323: "设为积分商品",
    add324: "兑换所需要积分",
    ximi15: "条码",
    ximiadd28: "图片",
    ximiadd63: "新增",
    "ximiadd253": "库存",
    "ximiadd283": "动态管理",
    "ximiadd284": "新增动态",
    "ximiadd285": "编辑动态",
    "ximiadd286": "发布者",
    "ximiadd287": "主题",
    "ximiadd288": "封面图",
    "ximiadd289": "发布时间",
    "ximiadd290": "浏览",
    "ximiadd291": "最多上传9张",
    "ximiadd292": "请完善上面表单",
    "ximiadd293": "请选择发布者",
    "ximiadd294": "发布主题",
    "ximiadd295": "上传文章",
    "ximiadd296": "产品发布",
    "ximiadd297": "图片列表",
    "ximiadd298": "封面照片",
    "ximiadd299": "标题",
    "ximiadd300": "作者",
    "ximiadd301": "请输入商品关键字",
    "ximiadd302": "关联商品",
    "ximiadd303": "图片发布",
    "ximiadd304": "产品发布",
    "ximiadd305": "文章发布",
    add325: "积分商城",
    add326: "积分详情",
    add327: "兑换记录",
    add328: "积分设置",
    add329: "用户名、邮箱、电话",
    add330: "之前积分",
    add331: "获得积分",
    add332: "现有积分",
    add333: "兑换商品",
    add334: "数量",
    add335: "使用积分",
    add336: "用户名、邮箱、电话、商品名",
    add337: "订单满",
    add338: "送积分",
    add339: "暂无积分设置",
    add340: "可积分兑换的商品",
    add341: "请输入数字",
    add343: "兑换所需积分",
    add344: "已兑换数量",
    add345: "用户充值",
    add346: "请输入充值金额",
    add347: "会员充值",
    add348: "新增充值",
    add349: "充值金额",
    add350: "赠送金额",
    add351: "充值方案",
    add352: "用户名称",
    add353: "充值余额",
    add354: "送",
    add355: "充值",
    add356: "充值成功",
    add357: "用户信息",
    add358: "订单记录",
    add359: "充值记录",
    add360: "用户充值记录",
    add361: "充值/使用",
    add362: "余额",
    add363: "使用",
    add364: "常用文案",
    add365: "隐私政策",
    add366: "用户等级说明",
    add367: "积分规则",
    back: "返回",
    add369: "请输入用户姓名/电话",
    add370: "App链接",
    add371: "Ios",
    add372: "Android",
    add373: "扫码设置",
    add374: "扫码首页滚动图片",
    add376: "页码",
    add377: "扫码菜单",
    add378: "菜品跨分类显示",
    add379: "收银台菜品语言",
    add380: "请选择语言",
    add381: "每轮下单的间隔时间（分钟）",
    add382: "1111",
    add383: "1111",
    add384: "1111",
    add385: "1111",
    add386: "1111",
    add387: "1111",
    add388: "1111",
    add389: "1111",
    add390: "1111",
}
