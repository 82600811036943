import React, {Component, Fragment} from "react";
import initEditor from './initEditor';
import {Button, message, Upload, Tabs, Input} from 'antd';
import Req from "@util/fetch";
import {debounce} from "@util/util";
import Tr from "@util/translate";
import HelpCenter from './../HelpCenter';
import RegisterAgreement from './../RegisterAgreement';
import ScanDeclartion from './../ScanDeclartion';
import PaperWorkCommon from "../PaperWorkCommon";

const {TabPane} = Tabs;

export default class AboutUs extends Component {
    constructor() {
        super();
        this.state = {
            key: "about",
            tabList: [
                {
                    name: Tr('discountList31'),
                    key: `about`
                },
                {
                    name: Tr('add167'),
                    key: `register`
                },
                {
                    name: Tr('add321'),
                    key: `scane`
                },
                {
                    name: Tr('add168'),
                    key: `copyright`
                },
                {
                    name: Tr('add365'),
                    key: `privacy`
                },
                {
                    name: Tr('add366'),
                    key: `level`
                },
                {
                    name: Tr('add367'),
                    key: `rule`
                },
            ]
        };
    }

    componentDidMount() {
        this.getAboutUs();
        this.getCopyRight();
    }

    getAboutUs = () => {
        Req(
            {
                url: `/v1/store?guid=undefined`,
                type: "GET",
                loadingTips: Tr("searching"),
                hideLoading: true,
                errorTips: Tr("discountList5")
            },
            res => {
                if (res) {
                    let data = res.data;
                    if (data.aboutCoverPath && data.about) {
                        let a = data.aboutCoverPath.split('/');
                        this.setState({
                            fileList: [{
                                uid: '1',
                                name: a[a.length - 1],
                                status: 'done',
                                url: data.aboutCoverPath,
                                thumbUrl: data.aboutCoverPath,
                            }]
                        })
                        initEditor({
                            container: this.container,
                            setEditor: (editor) => {
                                this.editor = editor
                            },
                            html: data.about
                        });
                    } else {
                        initEditor({
                            container: this.container,
                            setEditor: (editor) => {
                                this.editor = editor
                            }
                        });
                    }
                }
            }
        );
    }
    getCopyRight = () => {
        Req(
            {
                url: `/v1/webCopyright`,
                type: "GET",
                loadingTips: Tr("searching")
            },
            res => {
                if (res) {
                    this.setState({
                        copyrightData: res.data || ''
                    })
                }
            }
        );
    }
    save = () => {
        const {fileList} = this.state;
        const about = this.editor.txt.html();
        let file = fileList && fileList[0],
            aboutCoverPath;
        // if(!file){
        //     message.error(Tr("aboutus1"));
        //     return ;
        // }
        if (file && file.response) {
            if (file.response.isError) {
                // message.error(Tr("aboutus1"));
                // return ;
            } else {
                aboutCoverPath = file.response.data.url;
            }

        } else if (file && !file.response) {
            aboutCoverPath = file.url;
        }
        Req({
            url: "/v1/store/addAbout",
            type: "POST",
            data: {
                aboutCoverPath,
                about
            }
        }, res => {
            if (res) {
                this.clearSecondType();
                message.success(Tr("saveSuccess"))
            }
        })
    }

    saveCopyRight = () => {
        const {copyrightData} = this.state;
        Req({
            url: "/v1/webCopyright",
            type: "POST",
            data: copyrightData
        }, res => {
            if (res) {
                this.clearSecondType();
                message.success(Tr("saveSuccess"))
            }
        })
    }


    tabChange = (key) => {
        this.setState({
            key,
            secondType: null
        })
    }

    saveCopyRightDeb = debounce(this.saveCopyRight, 500);

    clearSecondType = ()=>{
        this.setState({ secondType: null })
    }

    render() {
        const {fileList, key, copyrightData, tabList, secondType} = this.state;
        const secondItem = tabList.find(item=>item.key === secondType);
        return (
            <div className="page-container">
                <h3>
                    <i className="icon iconfont icon-edit5"
                       style={{verticalAlign: "-2px", fontSize: "22px"}}> </i>
                    {Tr("add170")}
                </h3>
                <div style={{padding: 15}}>
                    <Tabs value={key} onChange={key => this.tabChange(key)} onTabClick={()=>this.setState({ secondType: null })}>
                        <TabPane tab={secondItem ? secondItem.name : Tr("add364")} key="about">
                            {
                                secondType ? null :
                                    <div className="table-list">
                                        <table>
                                            {
                                                tabList.map(item=>{
                                                    return (
                                                        <tr key={ item.key }>
                                                            <td>{ item.name }</td>
                                                            <td className="operate">
                                                                <a href="javascript:;" onClick={ ()=>this.setState({ secondType: item.key }) }>
                                                                    <i className="icon iconfont icon-edit"> </i>
                                                                </a>
                                                            </td>
                                                        </tr>
                                                    )
                                                })
                                            }
                                        </table>
                                    </div>
                            }
                            <div style={{ display: secondType === 'about' ? 'block' : 'none' }}>
                                <Upload
                                    action={window.serverUrl + "/store/v1/upload"}
                                    fileList={fileList}
                                    listType='picture'
                                    onChange={({file, fileList}) => {
                                        this.setState({
                                            fileList: fileList.length ? [file] : []
                                        })
                                    }}
                                >
                                    <Button>{Tr("register27")}</Button>
                                </Upload>
                                <div>
                                    <div style={{margin: '35px auto'}} ref={container => {
                                        this.container = container
                                    }}></div>
                                </div>
                                <div>
                                    <Button type="primary" onClick={this.save}>{Tr("save")}</Button>
                                    <Button type="default" onClick={ this.clearSecondType } style={{ marginLeft: 15 }}>
                                        { Tr("back") }
                                    </Button>
                                </div>
                            </div>
                            {
                                secondType === 'register' ?
                                    <RegisterAgreement successFun={ this.clearSecondType }/>
                                    : null
                            }
                            {
                                secondType === 'scane' ?
                                    <ScanDeclartion successFun={ this.clearSecondType }/>
                                    : null
                            }
                            {
                                secondType === 'copyright' ?
                                    <Fragment>
                                        <Input value={copyrightData && copyrightData.content}
                                               style={{ margin: '15px 0', width: '50%'}}
                                               onChange={(e) => this.setState({copyrightData: { ...(copyrightData || {}), content: e.target.value }}, this.saveCopyRightDeb)}
                                               placeholder={Tr("add169")}/>

                                        <Button type="default" onClick={ this.clearSecondType } style={{ marginLeft: 15 }}>
                                            { Tr("back") }
                                        </Button>
                                    </Fragment>
                                    : null
                            }
                            {
                                secondType === 'privacy' ?
                                    <PaperWorkCommon type={3} successFun={ this.clearSecondType }/>
                                    : null
                            }
                            {
                                secondType === 'level' ?
                                    <PaperWorkCommon type={5} successFun={ this.clearSecondType }/>
                                    : null
                            }
                            {
                                secondType === 'rule' ?
                                    <PaperWorkCommon type={6} successFun={ this.clearSecondType }/>
                                    : null
                            }
                        </TabPane>
                        <TabPane tab={Tr('add166')} key="">
                            <HelpCenter/>
                        </TabPane>
                    </Tabs>
                </div>
            </div>
        );
    }
}
