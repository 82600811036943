/**
 * fetch发起请求
 * @param options  请求配置
 *        {
 *          method: string 请求类型
 *          url: string ...
 *          data: obj | string 请求数据
 *          static: bool 为true则不对data JSON.stringify 否则...
 *          headers: obj 自定义header头,
 *          loadingTips: str 请求文字
 *          errorTips: str 错误文字
 *          hideLoading: bool 隐藏加载提示
 *          deletePar: bool 是否对delete方式接口参数处理,
 *          blobData: bool,
 *          isNewOrderApi: bool ,//新版的订单接口，需要调用另外的接口
 *        }
 * @param success   成功回调
 * @param error     错误回调
 * @param message   antd提示
 * @constructor
 */
import {message} from "antd";
import Tr from "@util/translate";

let signData = {
    appkey: "key",
    sign: "signed",
    timestamp: 0
};

//下载文件流文件
function download(url, request, hide, fileName) {
    message.loading(Tr("choice18"));
    var xmlResquest = new XMLHttpRequest();
    xmlResquest.open("GET", url, true);
    xmlResquest.setRequestHeader("Content-type", "application/json");
    xmlResquest.setRequestHeader(
        "Authorization",
        request.headers["Authorization"]
    );
    xmlResquest.responseType = "blob";
    xmlResquest.onload = function (oEvent) {
        var content = xmlResquest.response;
        console.log(content);
        var elink = document.createElement("a");
        elink.download = fileName || "template.zip";
        elink.style.display = "none";
        var blob = new Blob([content]);
        elink.href = URL.createObjectURL(blob);
        document.body.appendChild(elink);
        elink.click();
        document.body.removeChild(elink);
        hide && hide()
    };
    xmlResquest.send();
}

export default function Req(options, success, error) {
    const userInfo = JSON.parse(localStorage.getItem("userInfo")) || {};
    let storeData = JSON.parse(localStorage.getItem("storeData")) || {};
    let request = {
        method: options.type,
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${(userInfo && userInfo.tokenContent) || ""}`
        },
        // credentials: 'include',
    };
    let storeInfo = storeData.currentStore || {};
    let timeZone = storeInfo.storeConf && storeInfo.storeConf.timeZone;
    if(timeZone){
        request.headers.is_timezone = timeZone;
    }
    if(options.autoContentType){
        delete request.headers["Content-Type"];
    }

    if(options.signal){
        request.signal = options.signal;
    }
    if (options.headers) {
        request.headers = {
            ...request.headers,
            ...options.headers
        }
    }

    if (options.data && options.static) {
        request.body = options.data;
    } else if (options.data) {
        // let _data = signData;
        let _data = options.data;
        if (typeof _data.data === "object" && !_data.data.storeGuid) {
            _data.data.storeGuid = userInfo.guid;
        }
        if(typeof _data === "object" && _data)_data.webClientId = window.webClientId
        request.body = JSON.stringify(_data);
    }

    let params = "";
    //get请求加上时间戳
    if (options.type === "GET") {
        options.url = options.url.replace("?&", "?");
        params =
            (options.url.indexOf("?") === -1 ? "?" : "&") +
            `time=${new Date().getTime()}`;
        params += `&webClientId=${window.webClientId}`
    }
    if (options.type === "DELETE" && options.deletePar) {
        let str = "?";
        if (options.url.includes("?")) {
            str = "&";
        }
        let data = signData;
        data.data = options.data;
        for (let key in data) {
            str += `&${key}=${data[key]}`;
        }
        options.url += str;
    }
    if (options.type === "FileDownLoad") {
        options.url = options.url.replace("?&", "?");
        params =
            (options.url.indexOf("?") === -1 ? "?" : "&") +
            `time=${new Date().getTime()}`;
        download(window.serverUrl + options.url + params, request, undefined, options.fileName);
        return;
    }

    //对url参数进行编码 兼容IE传中文参数
    let url = options.url;
    if (url.indexOf("?") !== -1) {
        let index = url.indexOf("?");
        let queryParams = url.slice(index + 1);
        url = url.slice(0, index + 1);
        url += encodeURI(queryParams);
    }

    if (!options.hideLoading && !window.loadingTipNums){
        window.hideLoadingTip = message.loading(options.loadingTips || Tr("choice18"), 0);
    }
    if(!options.hideLoading){
        window.loadingTipNums = (window.loadingTipNums || 0) + 1;
    }

    let serverUrl = options.isNewOrderApi ? window.newOrderServerUrl :window.serverUrl;
    if(!url.startsWith('/customer')){
        serverUrl += '/store';
    }

    Promise.race([
        fetch( serverUrl+ url + params, request),
        new Promise((resolve, reject) => {
            setTimeout(() => {
                reject(new Error("request timeout"));
            }, 600000);
        })
    ])
        .then(res => {
            if(!options.hideLoading){
                window.loadingTipNums --;
                if(!window.loadingTipNums){
                    window.hideLoadingTip && window.hideLoadingTip();
                }
            }
            if (res.status === 401) {
                error && error(401);
                if (
                    !["#/login", "#/", "#/register", "#/pdf"].includes(location.hash.split("?")[0])
                ) {
                    location.hash = "/login";
                }
            }
            if(options.blobData){
                console.log(res);
                return res.blob();
            }
            return res.json();
        })
        .then(res => {
            if (res.isError) {
                message.warn(options.errorTips || res.msg || '请求出错');
                success(null);
                return;
            }
            success(res);
        })
        .catch(e => {
            if(!options.hideLoading){
                window.loadingTipNums --;
                if(!window.loadingTipNums){
                    window.hideLoadingTip && window.hideLoadingTip();
                }
            }
            if (error) {
                error(e);
            }
            // message.error('服务器错误');
            console.log("错误信息：", e);
        });
}
