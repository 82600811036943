import React, { Component } from 'react';
import './index.scss';
import {Button, Input, message, Modal} from "antd";
import Req from "@util/fetch";
import Tr from "@util/translate";
import initEditor from "@/component/Admin/Store/AboutUs/initEditor";

export default class RegisterAgreement extends Component{
    constructor(props){
        super(props)
    }
    state = {
        editData: {}
    }
    componentDidMount() {
        this.getData();
    }
    getData = ()=>{
        Req({
            url: "/v1/copywritingSettings?type=4",
            type: "GET"
        }, res=>{
            if(res){
                this.setState({
                    editData: res.data && res.data[0] || {}
                }, ()=>{
                    initEditor({
                        container: this.container,
                        setEditor: (editor)=>{ this.editor = editor },
                        html: this.state.editData.content
                    });
                })
            }

        })
    }

    save = ()=>{
        const { editData } = this.state;
        const { successFun } = this.props;
        let data = {
            ...editData,
            content: this.editor.txt.html(),
            type: 4
        };
        Req({
            url: "/v1/copywritingSettings",
            type: "POST",
            data
        }, res=> {
            if (res) {
                message.success(Tr("saveSuccess"));
                successFun && successFun();
            }
        })
    }

    render(){
        const { editData={} } = this.state;
        const { successFun } = this.props;

        return (
            <div>
                <div className="flex-center">
                    <Input placeholder={ Tr("add89") } style={{width: 300}}
                           onChange={e => this.setState({editData: {...editData, title: e.target.value}})}
                           value={editData.title}/>
                    <div className="tips">&nbsp;&nbsp;&nbsp;&nbsp;{ Tr('add322') }</div>
                </div>

                <div style={{ margin: '35px auto' }} ref={ container=>{ this.container = container } }>
                </div>

                <div className="btns">
                    <Button type="primary" onClick={() => this.save()}>
                        { Tr("save") }
                    </Button>
                    <Button type="default" onClick={ successFun } style={{ marginLeft: 15 }}>
                        { Tr("back") }
                    </Button>
                </div>
            </div>
        )
    }
}
