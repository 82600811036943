/**
 * Created by chenfei on 2018/2/5.
 */

import React from "react";
import ReactDom from "react-dom";
import Root from "./component/Root";
import initRedux from "./redux/initRedux";


switch (process.env.NODE_ENV) {
    case "DEV":
        // window.serverUrl = window.protocolVar + "//ottshoppingapi.didigo.es";
        window.serverUrl = window.protocolVar + "//ottshoppingapi.didigo.es";
        break;
    case "STAGING":
        window.serverUrl = window.protocolVar + "//ottshoppingapi.didigo.es";
        break;
    case "PROD":
        window.serverUrl = window.protocolVar + "//ottshoppingapi.didigo.es";
        break;
    default:
        window.serverUrl = window.protocolVar + "//ottshoppingapi.didigo.es";
}
switch (process.env.NODE_ENV) {
    case "DEV":
        // window.newOrderServerUrl = window.protocolVar + "//ottshoppingapi.didigo.es";
        window.newOrderServerUrl = window.protocolVar + "//ottshoppingapi.didigo.es";
        break;
    case "STAGING":
        window.newOrderServerUrl = window.protocolVar + "//ottshoppingapi.didigo.es";
        break;
    case "PROD":
        window.newOrderServerUrl = window.protocolVar + "//ottshoppingapi.didigo.es";
        break;
    default:
        window.newOrderServerUrl = window.protocolVar + "//ottshoppingapi.didigo.es";
}

const store = initRedux();
//监听state变化
// store.subscribe(() => console.log(store.getState()));

let container = document.getElementById("container");
let node = <Root store={store} />;

ReactDom.render(node, container);
