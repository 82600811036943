import React, {Component} from "react";
import {connect} from "react-redux";
import {Route, Switch} from "react-router-dom";
import {
    AboutUs,
    BusinessHours,
    DiscountEdit,
    DiscountList,
    BannerEdit,
    BannerEdit2,
    GoodsEdit,
    GoodsKindsEdit,
    GoodsKindsList,
    GoodsList,
    OrderDetail,
    OrderList,
    PaySetting,
    PostEdit,
    PostList,
    Printer,
    PropEdit,
    PropList,
    RoleEdit,
    RoleList,
    StaffEdit,
    StaffList,
    StoreEdit,
    TableManage,
    TurnOver,
    TurnOverOrder,
    UpdatePwd,
    UserList,
    UserDetail,
    UserOrder,
    Statistics,
    CustomerRank,
    DishRank,
    PublishDetail,
    StoreSetting,
    StoreAddBank,
    InvoiceList,
    MailTemplate,
    MailTemplateEdit,
    MailPush,
    MailPushDetail,
    Recharge,
    DynamicList,
    DynamicDetail,
    PointsSetting,
    ExchangeHistory,
    PointsDetail
} from "./../component";
import Req from "@/util/fetch";
import Header from "./Header";
import LeftMenu from "./LeftMenu";
import {actions} from "@/redux/action/common";
import "./index.scss";
import StoreList from "@/component/Admin/Store/StoreList";
import io from 'socket.io-client';
import {DndProvider} from "react-dnd";
import {HTML5Backend} from "react-dnd-html5-backend";
import {getUrlParams} from "@util/util";

let socket;

export default @connect(
    state => {
        return {
            userInfo: state.global.userInfo || null,
        };
    },
    dispatch => {
        return {
            storeListAction: data => {
                dispatch(actions.storeList(data));
            }
        };
    }
)
class Admin extends Component {
    constructor() {
        super();
        this.socket = null;
    }
    componentDidMount() {
        // this.getStoreData();
        this.connectWebsocket();
        //保存部分页面的查询参数以保证返回时可以还原前面的状态
        window.pageQueryParams = {
            userListQuery: null
        }
        if(getUrlParams('backUrl')){
            sessionStorage.setItem('backUrl', getUrlParams('backUrl'))
        }
    }
    componentWillUnmount(){
        window.pageQueryParams = null;
        // 断开websocket
        try{
            this.socket.disconnect()
            this.socket.destroy();
            this.timer && clearInterval(this.timer);
            for (let listener in this.socket.$events) {
                if (listener != undefined) {
                    this.socket.removeAllListeners(listener);
                }
            }
        }catch (e) {
            console.log("断开websocket链接发生错误，错误信息：", e)
        }
    }
    // 链接websocket
    connectWebsocket = () => {
        switch (process.env.NODE_ENV) {
            case 'DEV':
                this.socket = io(window.protocolHTTPS + '//ottshoppingapi.didigo.es:13000')
                break
            case 'STAGING':
                this.socket = io(window.protocolHTTPS + '//ottshoppingapi.didigo.es:13000')
                break;
            case 'PROD':
                this.socket = io(window.protocolHTTPS + '//ottshoppingapi.didigo.es:13000')
                break;
            default:
                this.socket = io(window.protocolHTTPS + '//ottshoppingapi.didigo.es:13000')
        }
        this.socket.connect();
        this.socket.on('disconnect', ()=>{
            console.log("socket 断开链接");
            this.timer && clearInterval(this.timer);
            this.timer = setInterval(()=>{
                this.socket.connect();
            }, 10000);
        });
        this.socket.on('connect', ()=>{
            console.log('socket 连接成功');
            this.timer && clearInterval(this.timer);
            this.timer = setInterval(()=>{
                this.socket.emit('msg','ping');
            }, 10000);
        });
        this.socket.on('reconnect', ()=>{
            console.log("重新连接到服务器");
            this.timer && clearInterval(this.timer);
            this.timer = setInterval(()=>{
                this.socket.emit('msg','ping');
            }, 10000);
        });
        const storeData = JSON.parse(localStorage.getItem("storeData"));
        if (window.clientName !== 'android') {
            this.socket.on(`message_store_print_${storeData.currentStore.guid}`, (data) => {
                console.log('接收到打印消息', data);
                data = JSON.parse(data);
                window.testPrinterExisted(data.name, ()=>{
                    switch (data.type) {
                        case 'locale':
                            if (window['jsObj'] || window['printData']) {
                                windowPrint(data, ()=>{ this.setPrinted(data.orderGuid) });
                            } else {
                                console.log('打印方法不存在');
                            }
                            break;
                        case 'web':
                            break;
                        default: ;
                    }
                })
            })
            this.socket.on(`message_print_daily_for_${window.webClientId}`, (data) => {
                console.log('接收到客户端打印消息', data);
                data = JSON.parse(data);
                window.testPrinterExisted(data.name, ()=>{
                    switch (data.type) {
                        case 'locale':
                            if (window['jsObj'] || window['printData']) {
                                windowPrint(data, ()=>{ data.orderGuid && this.setPrinted(data.orderGuid) });
                            } else {
                                console.log('打印方法不存在');
                            }
                            break;
                        case 'web':
                            break;
                        default: ;
                    }
                })
            })
            this.socket.on(`message_print_monthly_for_${window.webClientId}`, (data) => {
                console.log('接收到客户端打印消息', data);
                data = JSON.parse(data);
                window.testPrinterExisted(data.name, ()=>{
                    switch (data.type) {
                        case 'locale':
                            if (window['jsObj'] || window['printData']) {
                                windowPrint(data, ()=>{ data.orderGuid && this.setPrinted(data.orderGuid) });
                            } else {
                                console.log('打印方法不存在');
                            }
                            break;
                        case 'web':
                            break;
                        default: ;
                    }
                })
            })
            this.socket.on(`message_print_monthly_for_${storeData.currentStore.guid}`, (data) => {
                console.log('接收到日营业额 月营业额打印消息', data);
                data = JSON.parse(data);
                switch (data.type) {
                    case 'locale':
                        if (window['printData'] || window['jsObj']) {
                            windowPrint(data);
                        } else {
                            console.log('打印方法不存在');
                        }
                        break;
                    case 'web':
                        break;
                    default: ;
                }
            })
            console.log('监听发票打印');
            this.socket.on(`message_print_invoice_for_${storeData.currentStore.guid}`, (data) => {
                console.log('接收到发票打印消息', data);
                data = JSON.parse(data);
                switch (data.type) {
                    case 'locale': // 发生了本地打印事件，需要前端自行调用打印机接口进行打印.
                        if (window.jsObj || window.printData) {
                            windowPrint(data);
                        } else {
                            console.log('打印方法不存在');
                        }
                        break;
                    case 'web':
                        break;
                    // 发生了云打印事件，其中消息格式与本地打印机相同
                    // 不同的是，前端不再需要调用打印逻辑，只需要通知用户发生了一起打印事件即可
                    default: ;
                }
            })
            this.socket.on(`message_print_invoice_for_${window.webClientId}`, (data) => {
                console.log('接收到发票客户端打印消息', data);
                data = JSON.parse(data);
                switch (data.type) {
                    case 'locale': // 发生了本地打印事件，需要前端自行调用打印机接口进行打印.
                        if (window.jsObj || window.printData) {
                            windowPrint(data);
                        } else {
                            console.log('打印方法不存在');
                        }
                        break;
                    case 'web':
                        break;
                    // 发生了云打印事件，其中消息格式与本地打印机相同
                    // 不同的是，前端不再需要调用打印逻辑，只需要通知用户发生了一起打印事件即可
                    default:
                        ;
                }
            })
            this.socket.on(`message_print_daily_for_${storeData.currentStore.guid}`, (data) => {
                console.log('接收到日结账打印消息', data);
                data = JSON.parse(data);
                switch (data.type) {
                    case 'locale':
                        if (window['printData'] || window["jsObj"]) {
                            windowPrint(data);
                        } else {
                            console.log('打印方法不存在');
                        }
                        break;
                    case 'web':
                        break;
                    default:
                        ;
                }
            })
            function windowPrint(data, callBack){
                let count = data.cnt;
                for (let i = 0; i < count; i++) {
                    let uniqueValue = data.orderGuid + '_' + data.sceneType + '_' + i + '_' + data.guid//如果不是唯一的值可能会丢单
                    if(window.printData){
                        window.printData({
                            str: data.content,
                            deviceName: data.name,
                            unique: uniqueValue
                        }, (res) => {
                            if (res === 'success-' + uniqueValue) {
                                console.log('print success');
                                callBack && callBack();
                            }
                        })
                    }else if(window.jsObj){
                        window.jsObj.PrintDocument && window.jsObj.PrintDocument(
                            data.content,
                            data.name,
                            uniqueValue,
                            (res) => {
                                if (res === 'success-' + uniqueValue) {
                                    // set Printed here
                                    console.log('print success');
                                    if(data.orderGuid)this.setPrinted(data.orderGuid);
                                }
                            }, 1)
                    }
                }
            }
        } else if (window.clientName === 'android') {
            this.socket.on(`message_store_android_print_${storeData.currentStore.guid}`, (data) => {
                console.log('接收到打印消息', data);
                data = JSON.parse(data);
                try {
                    if (data.drawer) {
                        Android.openCash()
                    }
                    switch (data.type) {
                        case 'locale':
                            setTimeout(() => {
                                Android.printData("<PRINTER repeat='" + data.cnt + "'> " + data.content, data.name);
                                this.setPrinted(data.orderGuid);
                            }, 2000);

                            break;
                        case 'web':
                            break;
                        default: ;
                    }
                } catch (err) {
                    console.log(err);
                }
            })
        }
    }
    //设置订单为已打印
    setPrinted = (guid) => {
        Req({
            url: `/v1/order/${guid}/printed`,
            type: "POST",
        }, res => {
            if(res){
                console.log(`设置${guid}为已打印成功`);
            }
        })
    }

    getStoreData = () => {
        Req(
            {
                url: `/v1/store/mySelectList`,
                type: "GET",
                hideLoading: true
            },
            res => {
                if (res) {
                    this.props.storeListAction(res.data);
                }
            }
        );
    };

    render() {
        return (
            <div className="admin-page">
                <div className="content-container">
                    <LeftMenu/>
                    {
                        <DndProvider backend={ HTML5Backend }>
                            <div className="content">
                                <Switch>
                                    <Route path="/admin" exact component={Statistics}/>
                                    <Route path="/admin/rank" exact component={Statistics}/>
                                    <Route path="/admin/rank/customer" exact component={CustomerRank}/>
                                    <Route path="/admin/rank/dish" exact component={DishRank}/>
                                    <Route path="/admin/publish/:id" exact component={PublishDetail}/>
                                    <Route path="/admin/goods" exact component={GoodsList}/>
                                    <Route path="/admin/goods/list" component={GoodsList}/>
                                    <Route path="/admin/goods/edit/:id" component={GoodsEdit}/>
                                    <Route path="/admin/goodsprop/list" component={PropList}/>
                                    <Route path="/admin/goodsprop/edit/:id" component={PropEdit}/>
                                    <Route path="/admin/gkinds/list" component={GoodsKindsList}/>
                                    <Route path="/admin/gkinds/edit/:id" component={GoodsKindsEdit}/>
                                    <Route path="/admin/staff/list" component={StaffList}/>
                                    <Route path="/admin/staff/pwd/:id" component={UpdatePwd}/>
                                    <Route path="/admin/user/pwd/:id" component={UpdatePwd}/>
                                    <Route path="/admin/staff/edit/:id" component={StaffEdit}/>
                                    <Route path="/admin/post/list" component={PostList}/>
                                    <Route path="/admin/post/edit/:id" component={PostEdit}/>
                                    <Route path="/admin/role/list" component={RoleList}/>
                                    <Route path="/admin/role/edit/:id" component={RoleEdit}/>
                                    <Route path="/admin/order/list" component={OrderList}/>
                                    <Route path="/admin/order/detail/:id" component={OrderDetail}/>
                                    <Route path="/admin/store/list" component={StoreList}/>
                                    <Route path="/admin/store1/setting" component={StoreSetting}/>
                                    <Route path="/admin/store/edit/:id" component={StoreEdit}/>
                                    <Route path="/admin/store/bank/:id" component={StoreAddBank}/>
                                    <Route path="/admin/printer" component={Printer}/>
                                    <Route path="/admin/paysetting" component={PaySetting}/>
                                    <Route path="/admin/aboutus" component={AboutUs}/>
                                    <Route path="/admin/table" component={TableManage}/>
                                    <Route path="/admin/discount/list" component={DiscountList}/>
                                    <Route path="/admin/discount/edit/:id" component={DiscountEdit}/>
                                    <Route path="/admin/banner/edit/:id" component={BannerEdit}/>
                                    <Route path="/admin/scanebanner/edit/:id" component={BannerEdit2}/>
                                    <Route path="/admin/businesshours" component={BusinessHours}/>
                                    <Route path="/admin/turnover/order" component={TurnOverOrder}/>
                                    <Route path="/admin/turnover/:type" component={TurnOver}/>
                                    <Route path="/admin/userlist" exact component={UserList}/>
                                    <Route path="/admin/template" exact component={MailTemplate}/>
                                    <Route path="/admin/template/:id" component={MailTemplateEdit}/>
                                    <Route path="/admin/push" exact component={MailPush}/>
                                    <Route path="/admin/push/:id" component={MailPushDetail}/>
                                    <Route path="/admin/user/recharge" exact component={Recharge}/>
                                    <Route path="/admin/userorder/:id" component={UserOrder}/>
                                    <Route path="/admin/userdetail/:id" component={UserDetail}/>
                                    <Route path="/admin/invoice/list" component={InvoiceList}/>

                                    <Route path="/admin/dynamic/list" component={DynamicList}/>
                                    <Route path="/admin/dynamic/detail/:id" component={DynamicDetail}/>
                                    <Route path="/admin/points/detail" component={PointsDetail}/>
                                    <Route path="/admin/points/record" component={ExchangeHistory}/>
                                    <Route path="/admin/points/setting" component={PointsSetting}/>
                                </Switch>
                            </div>
                        </DndProvider>
                    }
                </div>
            </div>
        );
    }
}