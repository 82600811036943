import React, {Component, Fragment} from "react";
import {connect, Provider} from "react-redux";
import {HashRouter, Route} from "react-router-dom";
import {Content, Login, Register, PdfRead} from "./component";
import Admin from "./Admin";
import {actions} from "./../redux/action/common";
import Req from "./../util/fetch";
import "./../style/style.scss";
import PrintQrcode from './Admin/TableManage/Print';
import PrintStoreQrcode from './Admin/Store/StorePrinter';
import moment from "moment";
import {Modal} from "antd";
import { v4 as generateUid } from 'uuid';
import ConfrimPassword from "./common/ConfrimPassword";
import Tr from "@util/translate";
import {getUrlParams} from "@util/util";

@connect(state => {
        return {
            userInfo: state.global.userInfo || null,
            lang: state.global.lang
        };
    },
    dispatch => {
        return {
            langActions: data => {
                dispatch(actions.lang(data));
            }
        };
    })
export default class Root extends Component {
    constructor(props) {
        super(props);
        this.state = {
            lang: null
        }
    }

    componentDidMount() {
        if(getUrlParams('userInfo')){
            localStorage.setItem('userInfo', decodeURIComponent(getUrlParams('userInfo')))
        }
        window.webClientId = generateUid(); //生成客户端id 用于打印消息区分客户端
        window.hasPermisionCode = this.hasPermisionCode;
        window.confrimPasswordAction = this.confrimPassword;
        window.refreshUserInfo = this.getUserInfo;
        window.stopRefreshDataFunc = (flag)=>{
            window.stopRefreshData = flag;
            setTimeout(()=>{
                window.stopRefreshData = false;
            }, 5000);
        }
        window.getAllGoodsApiRoot = this.getCacheAction;
        window.clearGoodsCache = ()=>{
            sessionStorage.removeItem("goodsListData");
            sessionStorage.removeItem("goodsListDataBuffet");
            sessionStorage.removeItem("commodityCategoryData");
        }
        window.loadGoogleScriptTimer = setInterval(()=>{
            try {
                if(google){
                    console.log(new google.maps.Geocoder());
                    window.loadGoogleScriptTimer && clearInterval(window.loadGoogleScriptTimer)
                }
            }catch (e){
                console.log(e);
            }
        }, 100)
        window.getStoreInfo = this.getStoreInfo;
        window.getAdultChildGoods = this.getAdultChildGoods;

        if (localStorage.getItem("userInfo") && !["#/", "#/login", "#/register", "#/pdf"].includes(location.hash.split("?")[0])) {
            this.getUserInfo();
        } else {
            this.setState({ render: true })
            if(["#/", "#/login", "#/register", "#/pdf"].includes(location.hash.split("?")[0])) return ;
            location.hash = "/";
        }

        //若线上不是https则改为https
        if(!(location.hostname === 'localhost') && location.protocol === 'http:'){
            location.protocol = 'https:'
        }
    }

    componentWillReceiveProps(next) {
        if (next.lang !== window.selectedLanguage) {
            window.selectedLanguage = next.lang;
        }
    }
    getAdultChildGoods = (list)=>{
        window.adultChildGoods = list.filter(item=>item.is_people);
    }
    getCacheAction = ()=>{
        let storeData = JSON.parse(localStorage.getItem('storeData')) || {};
        if(storeData){
            //缓存商品
            let currentStore = storeData.currentStore || {};
            if(currentStore.storeConf && currentStore.storeConf.switchOrder && !sessionStorage.getItem('goodsListData')){
                this.getAllGoods(0, currentStore.defaultWay || 0);
            }
            if(currentStore.isOpenBuffet && !sessionStorage.getItem('goodsListDataBuffet')){
                this.getAllGoods(1, currentStore.defaultWay || 0);
            }else if(sessionStorage.getItem('goodsListDataBuffet')){
                this.getAdultChildGoods((JSON.parse(sessionStorage.getItem('goodsListDataBuffet')) || {}).list || []);
            }
            //缓存商品分类
            if(!sessionStorage.getItem('commodityCategoryData')){
                this.getCategoryData(true);
            }
        }
    }
    getCategoryData = hideLoading => {
        Req(
            {
                url: `/v1/commodityCategory`,
                type: "GET",
                loadingTips: Tr("searching"),
                errorTips: Tr("goods3"),
                hideLoading: hideLoading
            },
            res => {
                if (res) {
                    if(res.data && res.data.length){
                        sessionStorage.setItem('commodityCategoryData', JSON.stringify(res.data));
                    }
                }
            }
        );
    };
    getAllGoods = (buffet, defaultWay) => {
        this.setState({
            allGoodsLoading: true
        })
        if(defaultWay === buffet){
            this.props.store.dispatch({
                type: "ALLGOODSLOADING",
                data: true
            });
        }
        Req(
            {
                // url: '/v1/commodity/all?buffet=' + buffet + `&type=${buffet ? 2 : 0}`,
                url: '/v1/commodity/es?buffet=' + buffet + `&type=${buffet ? 2 : 0}&pageSize=10000`,
                type: "GET",
                hideLoading: true,
            },
            res => {
                if(defaultWay === buffet){
                    this.props.store.dispatch({
                        type: "ALLGOODSLOADING",
                        data: false
                    });
                }
                if (res && res.data) {
                    let list = res.data && res.data.data || [];
                    // let list = res.data || [];
                    if(buffet){
                        this.getAdultChildGoods(list);
                    }
                    sessionStorage.setItem(buffet ? 'goodsListDataBuffet' : 'goodsListData', JSON.stringify({
                        getDate: buffet ? moment().format('YYYY-MM-DD HH:mm:ss') : null,
                        list,
                        buffet
                    }));
                }
            }
        )
    };
    hasPermisionCode = (code)=>{
        const { userInfo } = this.props;
        let permissions = userInfo && userInfo.permissions || [];
        if(!permissions || !permissions.includes(code)){
            return false;
        }
        return true;
    }
    confrimPassword = (code, callback)=>{
        const { userInfo } = this.props;
        let role_permission_pwd = userInfo && userInfo.role_permission_pwd || [];
        if(window.showConfrimPassword) return ;
        if(role_permission_pwd.includes(code)){
            window.showConfrimPassword = true;
            Modal.confirm({
                className: 'confirmUsersPasswordModal',
                content: <ConfrimPassword callback={ callback } />,
                icon: null,
                maskClosable: true,
                onCancel() {
                    window.showConfrimPassword = false;
                }
            })
        }else{
            callback && callback()
        }
    }

    getUserInfo = (callback) => {
        if (["#/", "#/login", "#/register", "#/pdf"].includes(location.hash)) {
            return false;
        }
        Req(
            {
                url: "/v1/account",
                type: "GET"
            },
            res => {
                if(res){
                    if(callback && typeof callback === 'function'){
                        callback(res.data);
                        return ;
                    }
                    localStorage.setItem("storeData", JSON.stringify(res.data));
                    this.setState({ render: true })
                    this.getCacheAction();
                    this.props.store.dispatch(
                        actions.userInfo({
                            ...(JSON.parse(localStorage.getItem("userInfo")) || {}),
                            headImage: res.data.headImage,
                            agent: res.data.agent,
                            permissions: res.data.permissions,
                            role_permission_pwd: res.data.role_permission_pwd
                        }),
                    );
                    let sessionLang = localStorage.getItem('lang');
                    if (!sessionLang) {
                        window.selectedLanguage = res.data.lang === 'null' ? 'zh' : res.data.lang;
                        this.props.langActions(res.data.lang);
                    } else {
                        window.selectedLanguage = sessionLang;
                        this.props.langActions(sessionLang);
                    }
                }
            },
            error => {
                this.setState({ render: true })
                this.props.store.dispatch(actions.userInfo(null));
            }
        );
    };

    render() {
        const {userInfo} = this.props || {};
        const {render} = this.state || {};
        return (
            <Provider store={this.props.store}>
                <HashRouter>
                    {
                        render ?
                            <Fragment>
                                <Route path="/" exact component={Login}/>
                                <Route path="/login" exact component={Login}/>
                                <Route path="/pdf" exact component={PdfRead}/>
                                <Route path="/register" exact component={Register}/>
                                {
                                    (userInfo || null) && this.props.lang && <Fragment>
                                        <Route path="/content" component={Content}/>
                                        <Route path="/admin" component={Admin}/>
                                        <Route path="/print" component={PrintQrcode}/>
                                        <Route path="/store/print/:guid" component={PrintStoreQrcode}/>
                                    </Fragment>
                                }
                            </Fragment>
                            : null
                    }
                </HashRouter>
            </Provider>
        );
    }
}

window.testPrinterExisted = (name, callBack)=>{
    try{
        if(window.getPrinterList){
            window.getPrinterList && window.getPrinterList(list=>{
                console.log('找到的打印机列表', list, name);
                if(list.find(item=>item.name === name)){
                    callBack && callBack();
                }else{
                    // alert(Tr('add290'));
                }
            })
        }else{
            try{
                let str = window.jsObj.GetPrintList();
                if(str){
                    let list = JSON.parse(str);
                    console.log('找到的打印机列表', list, name);
                    if(list.find(item=>item.name === name)){
                        callBack && callBack();
                    }else{
                        // alert(Tr('add290'));
                    }
                }
            }catch (e) {
                console.log(e);
            }
        }
    }catch(e){
        console.log(e);
    }
}