import React, { Fragment } from 'react';
import './index.scss';
import {Modal} from "antd";

export default class ImgPreview extends React.Component{
    constructor() {
        super();
    }
    state = {}

    imagePreview = url => {
        this.setState({
            previewImageUrl: url,
            previewVisible: true
        });
    };

    render(){
        const { style, className, src } = this.props;
        const { previewVisible, previewImageUrl } = this.state;

        console.log(className);

        return (
            <Fragment>
                <img style={{ ...(style || {}) }}
                     onClick={ ()=>this.imagePreview(src) }
                     className={"img-preview " + (className || '')}
                     src={ src } />
                <Modal
                    visible={previewVisible}
                    footer={null}
                    destroyOnClose={ true }
                    onCancel={() => {
                        this.setState({previewVisible: false});
                    }}
                >
                    <img
                        alt="example"
                        style={{width: "100%"}}
                        src={previewImageUrl}
                    />
                </Modal>
            </Fragment>
        )
    }
}