import React, {Component} from "react";
import {connect} from "react-redux";
import {actions} from "../../../redux/action/common";
import Tr from "@util/translate";
import "./index.scss";

export default @connect(
    state => {
        return {
            userInfo: state.global.userInfo || {}
        };
    },
    dispatch => {
        return {
            userInfoAction: data => {
                dispatch(actions.userInfo(data));
            }
        };
    }
)
class Header extends Component {
    constructor(props) {
        super(props);
    }

    loginOut = () => {
        localStorage.removeItem("token");
        location.hash = "/login";
    };

    render() {
        const {userInfo = {}} = this.props || {};
        return (
            <div className="header">
                <div className="user">
                    <i className="icon iconfont icon-user"> </i>
                    {userInfo.name}
                    <i className="icon iconfont icon-sanjiaoxing-down"> </i>
                    <ul className="drop-menu">
                        <li onClick={this.loginOut}>
                            {Tr("loginOut")}
                        </li>
                    </ul>
                </div>
            </div>
        );
    }
}
