import React from 'react';
import './index.scss';
import Tr from "@util/translate";

export default class InputNumber extends React.Component{
    constructor(props) {
        super(props);
    }
    componentDidMount(){
        document.addEventListener('keydown', this.keyDownAction)
    }
    componentWillUnmount(){
        document.removeEventListener('keydown', this.keyDownAction)
    }
    keyDownAction = (e)=>{
        e.stopPropagation();
        e.preventDefault();
        const { sureAction } = this.props;
        let code = e.keyCode;
        if(code === 13){
            sureAction();
        }else if(code === 8){
            this.cancelInput();
        }else if((code >= 48 && code <= 57) || (code>=96 && code<=105)){
            let number = Math.abs(96 - code);
            if(code < 60){
                number = Math.abs(48 - code);
            }
            this.inputNumber(number);
        }
    }
    inputNumber = (val)=>{
        let { text='', textChange } = this.props;
        textChange(text + '' + val);

    }
    cancelInput = () => {
        const { text='', textChange } = this.props;
        if (text.length > 0)
            textChange(text.slice(0, -1));
    }
    render(){
        const { sureAction } = this.props;

        return (
            <div className="input-number-container">
                {["7", "8", "9", "4", "5", "6", "1", "2", "3", "0"].map(
                    val => {
                        return (
                            <li
                                onClick={() => {
                                    this.inputNumber(val);
                                }}
                            >
                                {val}
                            </li>
                        );
                    }
                )}
                <li onClick={ this.cancelInput }>
                    <img src="/public/img/del.svg" alt=""/>
                </li>
                <li className="sureBtn" onClick={ sureAction }>{ Tr('add197') }</li>
            </div>
        )
    }
}