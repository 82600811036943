import React, {Component} from "react";
import {connect} from "react-redux";
import {Link, withRouter} from "react-router-dom";
import {message, Modal} from "antd";
import classnames from "classnames";
import Req from "@/util/fetch";
import Tr from "@util/translate";
import {actions} from "@/redux/action/common";
import SelectAccount from './../SlectAccount';
import "./index.scss";
import OpenCash from "./component/OpenCash";
import PrinterOrder from "./component/PrinterOrder";

const { confirm } = Modal;

@connect(
    state => {
        return {
            userInfo: state.global.userInfo,
            orderIndex: state.global.orderIndex
        };
    },
    dispatch => {
        return {
            orderIndexAction: n => dispatch(actions.orderIndex(n))
        };
    }
)
class LeftMenu extends Component {
    constructor(props) {
        super(props);
        this.state = {
            orderData: {}
        };
    }

    componentDidMount() {
        window.setNowOrderData = data => {
            this.setState({
                orderData: data
            });
        };
        this.getStoreData();

        document.addEventListener('keydown', this.keydownAction);
    }

    componentWillUnmount() {
        document.removeEventListener('keydown', this.keydownAction)
    }

    getStoreData = () => {
        Req(
            {
                url: `/v1/account`,
                type: "GET",
                hideLoading: true
            },
            res => {
                if (res) {
                    this.setState({
                        storeData: (res.data && res.data.currentStore) || {}
                    });
                    localStorage.setItem("storeData", JSON.stringify(res.data));
                }
            }
        );
    };

    keydownAction = (e)=>{
        let { orderIndexAction, orderIndex=0, history } = this.props;
        if(window.discountEditModal) return ;
        if(e.key === 'F1'){
            if(!location.hash.includes('/content/choice')){
                history.push('/content/choice');
            }else{
                orderIndexAction(++orderIndex);
            }
        }else if(e.key === 'F2'){
            if(location.pathname !== '/content/choice') history.push('/content/choice');
            openTakewayusers();
        }else if(e.key === 'F3'){
            this.gotoPage(null, "/content/index", 'VIEW_REPORT')
        }else if(e.key === 'F4'){
            window.globalOpenCash && window.globalOpenCash();
        }
        if(['F1', 'F2', 'F3', 'F4'].includes(e.key)){
            e.stopPropagation();
            e.preventDefault();
        }
    }

    loginOut = () => {
        confirm({
            title: Tr("leftMenu1"),
            content: Tr("leftMenu2"),
            okText: Tr("sure"),
            cancelText: Tr("cancel"),
            onOk: () => {
                localStorage.removeItem("userInfo");
                localStorage.removeItem("lang");
                window.clearGoodsCache && window.clearGoodsCache();
                location.hash = "/";
            },
            onCancel() {
            }
        });
    };

    getAccountList= ()=>{
        if(!localStorage.getItem("storeSerial2")){
            location.hash = "/";
            return ;
        }
        const { userList } = this.state;
        if(userList && userList.length>1){
            this.switchShowChangeAccount(true);
            return ;
        }else if(userList && userList.length === 1){
            this.loginOut();
        }
        let storeSerial = 'E' + localStorage.getItem("storeSerial2");
        Req({
            url: `/v1/employees/filter?storeSerial=${storeSerial}`,
            type: "GET",
            loadingTips: Tr("serachuser")
        }, res=>{
            if(res && res.data && res.data.length && res.data.length>1){
                this.setState({
                    userList: res.data
                })
                this.switchShowChangeAccount(true);
            }else{
                this.loginOut();
            }
        })
    }

    gotoPage = (e, url, code)=>{
        if(window.hasPermisionCode(code)){
            window.confrimPasswordAction(code, ()=>{
                location.hash = url;
            });
        }else{
            alert(Tr("noOpenChashPer"));
            e.stopPropagation();
        }
    }
    switchShowChangeAccount = (visible)=>{
        window.showChangeAccount = visible;
        this.setState({ showChangeAccount: visible });
    }

    render() {
        const { showChangeAccount, userList, orderData } = this.state;
        let {location, userInfo = {}, orderIndexAction, orderIndex = 0, history} = this.props || {};
        return (
            <aside>
                <div className="head-img">
                    <img onClick={this.getAccountList} src={userInfo && userInfo.headImage}/>
                    <br/>
                    <span>{userInfo.name}</span>
                </div>

                <div className="btn-container">
                    <Link to="/content/choice">
                        <div
                            className={classnames({
                                menu: true,
                                active: location.pathname === "/content/choice"
                            })}
                            title={'F1'}
                            onClick={() => {
                                orderIndexAction(++orderIndex);
                            }}
                        >
                            <img
                                className="active"
                                src="./public/img/aside/rice_w.png"
                                alt=""
                            />
                            <img src="./public/img/aside/rice.png" alt=""/>
                            <span>{Tr("leftMenu5")}(F1)</span>
                        </div>
                    </Link>

                    <div
                        className={classnames({
                            menu: true
                        })}
                        title={'F2'}
                        onClick={ ()=>{
                            if(location.pathname !== '/content/choice') history.push('/content/choice');
                            openTakewayusers();
                        } }
                    >
                        <img src="./public/img/aside/takeaway.png" alt=""/>
                        <span>{Tr("choice37")}(F2)</span>
                    </div>

                    <a href="javascript:;" onClick={ (e)=>this.gotoPage(e, "/content/index", 'VIEW_REPORT') }>
                        <div
                            className={classnames({
                                menu: true,
                                active: location.pathname === "/content/index"
                            })}
                            title={'F3'}
                        >
                            <img
                                className="active"
                                src="./public/img/aside/chart_w.png"
                                alt=""
                            />
                            <img src="./public/img/aside/chart.png" alt=""/>
                            <span>{Tr("leftMenu7")}(F3)</span>
                        </div>
                    </a>

                    <OpenCash />

                    <PrinterOrder orderData={ orderData } />
                </div>

                <Modal
                    title={Tr("add160")}
                    width={ 800 }
                    className="select-account-modal"
                    visible={ showChangeAccount }
                    onCancel={ ()=>this.switchShowChangeAccount(false) }
                    footer={ null }
                >
                    <SelectAccount close={ ()=>this.switchShowChangeAccount(false) }
                                   userList={ userList || [] } />
                </Modal>
            </aside>
        );
    }
}

export default withRouter(LeftMenu);

function openTakewayusers(){
    setTimeout(()=>{
        if(!window.addTakeawayUsers){
            openTakewayusers();
            return ;
        }
        window.addTakeawayUsers && window.addTakeawayUsers();
    }, 200)
}
